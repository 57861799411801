.nm-module-scroll-highlight {
	width: 100%;
}

.nm-module-scroll-highlight .nm-scroll-highlight-textblock {
	display: inline-block;
	margin: var(--space-xxxl) 4% var(--space-xxl);
	opacity: 1;
	transition: all 750ms cubic-bezier(.445, .05, .55, .95);
	width: 92%;
}

.nm-js-enabled .nm-module-scroll-highlight .nm-scroll-highlight-textblock.nm-scroll-highlight-hidden {
	opacity: 0;
	transform: translateY(calc(50px));
}

.nm-module-scroll-highlight .nm-scroll-highlight-textblock.nm-scroll-highlight-textblock-white.nm-scroll-highlight-textblock-background {
	background-color: #000;
}

.nm-module-scroll-highlight .nm-scroll-highlight-textblock.nm-scroll-highlight-textblock-black.nm-scroll-highlight-textblock-background {
	background-color: #fff;
}

.nm-module-scroll-highlight .nm-scroll-highlight-textblock.nm-scroll-highlight-textblock-background {
	padding: var(--space-l) var(--space-xxl) var(--space-xxl);
	width: calc(92% - (2 * var(--space-xxl)));
}

.nm-module-scroll-highlight .nm-scroll-highlight-textblock .nm-scroll-highlight-textblock-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-m);
}

.nm-module-scroll-highlight .nm-scroll-highlight-textblock .nm-scroll-highlight-textblock-text {
	margin-bottom: var(--space-m);
}

.nm-module-scroll-highlight .nm-scroll-highlight-video-container {
	position: relative;
}

.nm-module-scroll-highlight .nm-scroll-highlight-video-container video {
	display: block;
}

.nm-module-scroll-highlight .nm-scroll-highlight-textoverlay-wrapper {
	position: relative;
}

.nm-module-scroll-highlight .nm-scroll-highlight-textoverlay {
	opacity: 0;
	padding: var(--space-xxl) 4% var(--space-xxxl);
	width: 92%;
}

.nm-module-scroll-highlight .nm-scroll-highlight-textoverlay-text {
	font-weight: var(--font-weight-bold);
}

.nm-module-scroll-highlight .nm-scroll-highlight-textoverlay.nm-scroll-highlight-highlighttext-two {
	position: absolute;
	top: 0;
}

.nm-module-scroll-highlight .nm-scroll-highlight-textoverlay.nm-scroll-highlights-textoverlay-white .audi-headline-order-3 {
	color: #fff;
}

@media all and (min-width: 768px) {
	.nm-module-scroll-highlight .nm-scroll-highlight-textblock {
		margin-right: 20%;
		width: 76%;
	}

	.nm-module-scroll-highlight .nm-scroll-highlight-textblock.nm-scroll-highlight-textblock-background {
		width: calc(76% - (2 * var(--space-xxl)));
	}

	.nm-module-scroll-highlight .nm-scroll-highlight-textblock.nm-scroll-highlight-textblock-right {
		margin-left: 20%;
		margin-right: 4%;
	}

	.nm-module-scroll-highlight .nm-scroll-highlight-video-container {
		display: inline-block;
		height: 100%;
		position: relative;
		width: 100%;
	}

	.nm-module-scroll-highlight .nm-scroll-highlight-video-container-wrapper {
		height: 0;
		padding-bottom: 56.25%;
	}

	.nm-module-scroll-highlight .nm-scroll-highlight-textoverlay-wrapper {
		left: 0;
		padding-bottom: 56.25%;
		position: absolute;
		top: 0;
		width: 100%;
	}

	.nm-module-scroll-highlight .nm-scroll-highlight-textoverlay {
		margin: var(--space-xxl) 4%;
		padding: 0;
		position: absolute;
		top: 0;
		width: 28%;
	}

	.nm-module-scroll-highlight .nm-scroll-highlight-textoverlay.nm-scroll-highlights-textoverlay-white.nm-scroll-highlight-textoverlay-background {
		background-color: #000;
	}

	.nm-module-scroll-highlight .nm-scroll-highlight-textoverlay.nm-scroll-highlight-textoverlay-background {
		background-color: #fff;
		padding: var(--space-l) var(--space-xxl) var(--space-xxl);
	}

	.nm-module-scroll-highlight .nm-scroll-highlight-textoverlay.nm-scroll-highlight-textoverlay-bottom {
		bottom: 0;
		top: auto;
	}

	.nm-module-scroll-highlight .nm-scroll-highlight-textoverlay.nm-scroll-highlight-textoverlay-right {
		right: 0;
	}
}

@media all and (min-width: 1024px) {
	.nm-module-scroll-highlight .nm-scroll-highlight-textblock {
		margin-right: 36%;
		width: 60%;
	}

	.nm-module-scroll-highlight .nm-scroll-highlight-textblock.nm-scroll-highlight-textblock-background {
		width: calc(60% - (2 * var(--space-xxl)));
	}

	.nm-module-scroll-highlight .nm-scroll-highlight-textblock.nm-scroll-highlight-textblock-right {
		margin-left: 36%;
	}
}

@media all and (min-width: 1440px) {
	.nm-module-scroll-highlight .nm-scroll-highlight-textblock {
		margin-left: 12%;
		width: 52%;
	}

	.nm-module-scroll-highlight .nm-scroll-highlight-textblock.nm-scroll-highlight-textblock-background {
		width: calc(52% - (2 * var(--space-xxl)));
	}

	.nm-module-scroll-highlight .nm-scroll-highlight-textblock.nm-scroll-highlight-textblock-right {
		margin-left: 36%;
	}
}
