[page-type="widescreen"] .nm-stage-wrapper {
	max-height: initial;
}

[page-type="widescreen"] .nm-stage-container {
	padding-top: 0;
}

.nm-module-stage {
	margin-bottom: 0;
	padding: 0;
	position: relative;
	user-select: none;
	width: 100%;
}

.nm-module-stage .nm-stage-media-item {
	background-size: 0;
	height: 0;
	overflow: hidden;
	padding-bottom: 56.25%;
}

.nm-module-stage .nm-stage-infocluster,
.nm-module-stage .nm-stage-eec-container {
	padding: 0 4%;
	z-index: 3;
}

.nm-module-stage .nm-stage-infocluster {
	margin-bottom: var(--space-xl);
	margin-top: var(--space-xl);
}

.nm-module-stage .nm-stage-eec-container {
	margin-bottom: var(--space-s);
	margin-top: var(--space-xl);
}

.nm-module-stage .nm-stage-eec-container .nm-stage-eec-label {
	margin-top: var(--space-m);
}

.nm-module-stage .nm-stage__asset-disclaimer {
	margin-bottom: var(--space-s);
}

.nm-module-stage .nm-stage-media-item img,
.nm-module-stage .nm-stage-media-item video {
	width: 100%;
}

.nm-module-stage .nm-stage-media-item video {
	display: none;
}

.nm-module-stage .nm-stage-media-item.nm-video-player {
	max-height: 100%;
	overflow: hidden;
	position: relative;
}

.nm-module-stage .nm-video-player-overlay {
	background-color: #000;
	height: 100%;
	opacity: .3;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 2;
}

.nm-module-stage .nm-video-player .nm-stage-video-play-button {
	cursor: pointer;
	height: 74px;
	left: 0;
	margin: 0 auto;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 74px;
	z-index: 5;
}

.nm-module-stage .nm-video-player .nm-stage-video-play-button .nm-icon-play-xxl {
	color: #fff;
	display: inline-block;
	fill: currentColor;
	height: 7.4rem;
	stroke: currentColor;
	width: 7.4rem;
}

.nm-module-stage .nm-video-player .nm-stage-video-play-button:hover .nm-icon-play-xxl {
	color: #e5e5e5;
}

.nm-module-stage .nm-video-player.nm-basis-video-playing .nm-stage-video-play-button,
.nm-module-stage .nm-video-player.nm-basis-video-playing .nm-video-player-overlay {
	display: none;
}

.nm-module-stage .nm-video-player .nm-stage-video-skip-button {
	bottom: var(--space-xl);
	display: none;
	position: absolute;
	right: 4%;
	z-index: 5;
}

.nm-module-stage .nm-video-player.nm-basis-video-playing .nm-stage-video-skip-button {
	display: flex;
}

.nm-module-stage .nm-video-player.nm-basis-video-playing,
.nm-module-stage .nm-video-player.nm-basis-video-autoplay-played {
	padding-bottom: 56.25%;
	transition: all 1000ms ease-out;
}

.nm-module-stage .nm-video-player.nm-basis-video-playing video,
.nm-module-stage .nm-video-player.nm-basis-video-autoplay-played video {
	display: block;
}

.nm-module-stage .nm-video-player img {
	position: absolute;
	top: 0;
}

.nm-module-stage .nm-video-player.nm-basis-video-playing img,
.nm-module-stage .nm-video-player.nm-basis-video-autoplay-played img {
	display: none;
}

.nm-module-stage .nm-stage-infocluster .nm-stage-infocluster-headline,
.nm-module-stage .nm-stage-infocluster .nm-stage-infocluster-subheadline {
	font-weight: var(--font-weight-bold);
	width: 100%;
}

.nm-module-stage .nm-stage-infocluster .nm-stage-infocluster-headline {
	margin-bottom: var(--space-m);
}

.nm-module-stage .nm-stage-infocluster .nm-stage-infocluster-subheadline {
	margin-bottom: var(--space-l);
}

.nm-module-stage .nm-stage-infocluster .nm-stage-infocluster-linkarea {
	margin-bottom: var(--space-xl);
}

.nm-module-stage .nm-stage-infocluster .nm-stage-infocluster-primarylink,
.nm-module-stage .nm-stage-infocluster .nm-stage-infocluster-secondarylink {
	display: flex;
	max-width: 768px;
	width: 100%;
}

.nm-module-stage .nm-stage-infocluster .nm-stage-infocluster-secondarylink {
	margin-top: var(--space-xs);
}

.nm-module-stage .nm-stage-eec-container .nm-stage-eec-show-inline {
	display: inline-block;
	margin-right: var(--space-l);
}

.nm-module-stage .nm-stage-eec-show-inline + .nm-stage-eec-show-inline {
	margin-right: 0;
}

.nm-module-stage .nm-stage-eec-container .audi-el-copy-s {
	opacity: .8;
}

@media all and (max-aspect-ratio: 1/1) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.nm-module-stage .nm-stage-media-item {
		background-size: 100%;
		padding-bottom: 31%;
	}
}

@media all and (min-aspect-ratio: 1/1) {
	.nm-module-stage .nm-stage-media-item {
		padding-bottom: 31%;
		transition: all 150ms ease-out;
	}
}

@media all and (min-width: 768px) {
	.nm-module-stage .nm-stage-infocluster {
		width: 92%;
	}

	.nm-module-stage .nm-stage-infocluster .nm-stage-infocluster-headline {
		width: 72%;
	}

	.nm-module-stage .nm-stage-infocluster .nm-stage-infocluster-subheadline {
		width: 44%;
	}

	.nm-module-stage .nm-stage-infocluster .nm-stage-infocluster-primarylink,
	.nm-module-stage .nm-stage-infocluster .nm-stage-infocluster-secondarylink {
		display: inline-flex;
		width: inherit;
	}

	.nm-module-stage .nm-stage-infocluster .nm-stage-infocluster-linkarea {
		display: flex;
	}

	.nm-module-stage .nm-stage-infocluster .nm-stage-infocluster-secondarylink {
		margin: 0 0 0 6px;
	}
}

@media all and (min-width: 1024px) {
	.nm-module-stage .nm-stage-infocluster {
		margin-bottom: 0;
		position: absolute;
		top: 0;
	}

	.nm-module-stage .nm-video-player.nm-basis-video-playing.nm-stage-hideonplay ~ .nm-stage-infocluster {
		display: none;
	}

	.nm-module-stage .nm-stage-infocluster .nm-stage-infocluster-linkarea {
		margin-bottom: var(--space-xl);
	}

	.nm-module-stage .nm-stage-infocluster.nm-stage-right {
		right: 0;
		text-align: right;
	}

	.nm-module-stage .nm-stage-infocluster.nm-stage-right .nm-stage-infocluster-headline {
		padding-left: 28%;
		text-align: right;
	}

	.nm-module-stage .nm-stage-infocluster.nm-stage-right .nm-stage-infocluster-subheadline {
		padding-left: 56%;
		text-align: right;
	}

	.nm-module-stage .nm-stage-infocluster.nm-stage-right .nm-stage-infocluster-linkarea {
		justify-content: flex-end;
		padding-left: 56%;
	}

	.nm-module-stage .nm-stage-infocluster.nm-stage-white .nm-stage-infocluster-headline,
	.nm-module-stage .nm-stage-infocluster.nm-stage-white .nm-stage-infocluster-subheadline {
		color: var(--color-white);
	}

	.nm-module-stage .nm-stage-infocluster.nm-stage-bottom {
		bottom: 0;
		top: inherit;
	}

	.nm-module-stage .nm-stage-eec-container {
		margin-bottom: var(--space-xl);
		margin-top: var(--space-s);
	}
}

@media all and (min-width: 1280px) {
	.nm-module-stage .nm-stage-eec-container {
		bottom: 0;
		margin-bottom: var(--space-l);
		position: absolute;
		width: 40%;
	}

	.nm-module-stage .nm-stage-eec-container.nm-stage-white .nm-stage-eec-label,
	.nm-module-stage .nm-stage-eec-container.nm-stage-white .nm-stage-eec-value,
	.nm-module-stage .nm-stage-eec-container.nm-stage-white .nm-stage__asset-disclaimer {
		color: var(--color-white);
	}

	.nm-module-stage .nm-stage-eec-container.nm-stage-white .nm-stage-eec-value-link:hover {
		color: #b3b3b3;
	}

	.nm-module-stage .nm-video-player.nm-basis-video-playing.nm-stage-hideonplay ~ .nm-stage-eec-container {
		display: none;
	}

	.nm-module-stage .nm-stage-eec-container.nm-stage-right {
		right: 0;
		text-align: right;
	}
}

@media all and (min-width: 1024px) and (min-aspect-ratio: 1/1) {
	.nm-stage-infocluster-headline,
	.nm-3col .nm-stage-infocluster-headline,
	[page-type=widescreen] .nm-3col .nm-stage-infocluster-headline {
		font-size: 2.7rem;
		line-height: 4.2rem;
	}

	.nm-stage-infocluster-subheadline,
	.nm-3col .nm-stage-infocluster-subheadline,
	[page-type=widescreen] .nm-3col .nm-stage-infocluster-subheadline {
		font-size: 1.8rem;
		line-height: 3rem;
	}
}

@media all and (min-width: 1440px) and (min-aspect-ratio: 1/1) {
	.nm-stage-infocluster-headline,
	.nm-3col .nm-stage-infocluster-headline,
	[page-type=widescreen] .nm-3col .nm-stage-infocluster-headline {
		font-size: 3.6rem;
		line-height: 5.4rem;
	}
}

@media all and (min-width: 1920px) and (min-aspect-ratio: 1/1) {
	.nm-stage-infocluster-headline,
	.nm-3col .nm-stage-infocluster-headline,
	[page-type=widescreen] .nm-3col .nm-stage-infocluster-headline {
		font-size: 4.2rem;
		line-height: 6.6rem;
	}

	.nm-stage-infocluster-subheadline,
	.nm-3col .nm-stage-infocluster-subheadline,
	[page-type=widescreen] .nm-3col .nm-stage-infocluster-subheadline {
		font-size: 2.4rem;
		line-height: 4.2rem;
	}
}

@media all and (max-width: 1023px) {
	.nm-stage-infocluster-primarylink,
	.nm-stage-infocluster-primarylink:active {
		background-color: var(--color-black);
		color: var(--color-white);
	}

	.nm-stage-infocluster-primarylink:hover,
	.nm-stage-infocluster-primarylink:focus {
		background-color: var(--color-grey-70);
	}

	.nm-stage-infocluster-secondarylink,
	.nm-stage-infocluster-secondarylink:active {
		border-color: var(--color-black) !important;
		color: var(--color-black) !important;
	}

	.nm-stage-infocluster-secondarylink:hover,
	.nm-stage-infocluster-secondarylink:focus {
		border-color: var(--color-grey-70) !important;
		color: var(--color-grey-70) !important;
	}
}
