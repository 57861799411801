.nm-module-drawer {
	display: block;
	max-width: 1920px;
	position: relative;
	width: 100%;
}

.nm-module-drawer[data-theme="dark"] {
	background-color: var(--color-grey-70);
	color: var(--color-white);
}

.nm-module-drawer[data-theme="light"] {
	background-color: var(--color-grey-30);
}

.nm-module-drawer.nm-open {
	transform: none !important;
}

.nm-module-drawer .nm-layer-content {
	max-width: 100%;
	padding-bottom: 0;
	width: auto;
}

/**
 * Inline-Mediagallery scroll-bar background-color FIX if used in Drawer
 */
.nm-module-drawer[data-theme="dark"] .nm-layer-content .nm-md-inline-mediagallery .nm-inline-mediagallery-slider-navigation {
	background-color: var(--color-grey-70);
}

.nm-module-drawer[data-theme="light"] .nm-layer-content .nm-md-inline-mediagallery .nm-inline-mediagallery-slider-navigation {
	background-color: var(--color-grey-30);
}

/**
 * header
 */
.nm-module-drawer .nm-drawer-header {
	display: flex;
	flex-flow: column;
	justify-content: center;
	padding: 0 0 var(--space-xl) 0;
}

.nm-module-drawer.nm-open .nm-drawer-header {
	transform: none;
}

/**
 * button
 */
.nm-module-drawer .nm-drawer-header .nm-drawer-button-container {
	min-height: calc(48px + var(--space-xl));
	z-index: 2;
}

.nm-module-drawer .nm-drawer-header .nm-drawer-button {
	background-color: transparent;
	display: flex;
	justify-content: center;
}

.nm-module-drawer.nm-open .nm-drawer-header .nm-drawer-button {
	fill: var(--color-white) !important;
	max-width: 1920px;
	width: 100%;
	z-index: 2;
}

.nm-module-drawer .nm-drawer-header .nm-drawer-button-wrapper,
.nm-module-drawer .nm-drawer-header .nm-drawer-button .nm-drawer-button-icon-wrapper,
.nm-module-drawer .nm-drawer-header .nm-drawer-button .nm-drawer-button-icon-wrapper svg {
	height: var(--icon-size-large);
	width: var(--icon-size-large);
}

.nm-module-drawer .nm-drawer-header .nm-drawer-button-wrapper {
	cursor: pointer;
	padding-top: var(--space-xl);
}

.nm-module-drawer .nm-drawer-header .nm-drawer-button .nm-drawer-button-icon-wrapper {
	transition: transform .5s cubic-bezier(.445, .05, .55, .95);
}

.nm-module-drawer.nm-open .nm-drawer-header .nm-drawer-button .nm-drawer-button-icon-wrapper {
	transform: rotate(45deg);
}

.nm-module-drawer.nm-open .nm-drawer-header .nm-drawer-button.nm-fixed-button {
	background-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .2) 100%);
	position: fixed !important;
	top: 0px !important;
}

[page-type=widescreen] .nm-module-drawer.nm-open .nm-drawer-header .nm-drawer-button.nm-fixed-button {
	max-width: 1920px;
}

body:not([page-type=widescreen]) .nm-module-drawer.nm-open .nm-drawer-header .nm-drawer-button.nm-fixed-button {
	max-width: 1177px;
	width: 92%;
}

.nm-nav-wrap + .nm-content .nm-module-drawer.nm-open .nm-drawer-header .nm-drawer-button.nm-fixed-button {
	position: fixed !important;
	top: 50px !important;
}

.nm-module-drawer .nm-drawer-header .nm-drawer-text {
	cursor: auto;
	display: flex;
	height: auto;
	justify-content: center;
	margin-top: var(--space-s);
	max-width: 1920px;
	opacity: 1;
	transition: opacity .5s cubic-bezier(.445, .05, .55, .95);
}

.nm-module-drawer.nm-open .nm-drawer-header .nm-drawer-text {
	opacity: 0;
}

.nm-module-drawer .nm-drawer-body {
	margin-top: 0;
	max-height: 0;
	overflow: hidden;
	padding-top: 0;
	position: relative;
	transition: max-height .6s cubic-bezier(.445, .05, .55, .95), margin-top .6s cubic-bezier(.445, .05, .55, .95), padding-top .6s cubic-bezier(.445, .05, .55, .95);
}

.nm-module-drawer.nm-open .nm-drawer-body {
	margin-top: calc(-1 * (var(--icon-size-large) + var(--space-xl) + var(--space-xl) + var(--space-s) + 2.4rem));
	padding-bottom: var(--space-xxxl);
	padding-top: calc(var(--icon-size-large) + var(--space-xl) + var(--space-xl));
}

.nm-module-drawer .nm-drawer-content > .nm-layer-wrapper {
	position: relative;
}

.nm-module-drawer .nm-drawer-content > .nm-layer-title {
	display: none;
}

.nm-module-drawer .nm-drawer-content {
	display: none;
}

.nm-module-drawer .nm-drawer-content.nm-drawer-content--is-visible {
	display: block;
}

.nm-module-drawer .nm-drawer-headline {
	font-weight: var(--font-weight-bold);
	margin: var(--space-xxxl) 4% var(--space-xl);
}

.nm-module-drawer .nm-drawer-content-flyout {
	margin-bottom: var(--space-xl);
	margin-top: var(--space-xxxl);
}

.nm-module-drawer .nm-drawer-headline + .nm-drawer-content-flyout {
	margin-top: 0 !important;
}

.nm-module-drawer [data-state=open].nm-drawer-content-flyout {
	margin: 0;
}

@media all and (min-width: 768px) {
	.nm-module-drawer [data-state=open].nm-drawer-content-flyout,
	.nm-module-drawer .nm-drawer-content-flyout,
	.nm-module-drawer .nm-drawer-headline {
		margin: var(--space-xxxl) 12% var(--space-xl);
	}
}
