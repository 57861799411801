.nm-vtp {
	background-color: var(--color-grey-80);
	display: block;
	padding: var(--space-s) 0 0;
}

.nm-vtp.nm-vtp--modelpage {
	margin-bottom: 0;
}

.nm-stage-wrapper ~ .vtp.parbase .nm-vtp {
	margin-bottom: 0;
}

.nm-vtp .audi-footnote-anchor__text {
	color: var(--color-white);
}

.nm-vtp .audi-footnote-anchor__text:hover {
	color: var(--color-grey-05);
}

.nm-vtp__dropdown-links-container {
	box-sizing: border-box;
	display: flex;
	flex-flow: row wrap;
	padding: 0 var(--space-column);
	width: 100%;
}

.nm-vtp__label-available-cars {
	color: var(--color-white);
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-s);
	width: 100%;
}

.nm-vtp.nm-vtp--modelpage .nm-vtp__label-available-cars {
	margin-bottom: 0;
}

.nm-vtp__link-container {
	display: none;
	padding-bottom: var(--space-s);
	width: 100%;
}

.nm-vtp__label-available-cars + .nm-vtp__link-container {
	margin-top: var(--space-s);
}

.nm-vtp__link-container.nm-vtp--active,
.nm-vtp__eec-container.nm-vtp--active {
	display: block;
}

.nm-vtp__link-container > a {
	display: block;
	margin-bottom: var(--space-s);
	width: 100%;
}

.nm-vtp__link-count {
	font-weight: var(--font-weight-bold);
	margin-right: var(--space-xs);
}

.nm-vtp__eec-container {
	background-color: var(--color-grey-60);
	box-sizing: border-box;
	display: none;
	margin: 0;
	padding: var(--space-xs) var(--space-column);
	width: 100%;
}

.nm-vtp__eec-container.hidden {
	height: 0;
	overflow: hidden;
	padding: 0;
}

.nm-vtp__eec-fuel {
	color: var(--color-white);
	margin-bottom: var(--space-xs);
}

.nm-vtp__eec-label,
.nm-vtp__eec-value {
	color: var(--color-white);
}

.nm-vtp__eec-link {
	margin-top: var(--space-xs);
}

.nm-vtp__eec-container .nm-vtp__eec-link:hover {
	color: #f2f2f2;
}

.nm-vtp__dropdown-flyout {
	flex-grow: 1;
	margin: 0 0 var(--space-m);
	width: 100%;
}

.nm-vtp__dropdown-flyout .audi-dropdown-flyout__module-label {
	color: var(--color-grey-30);
}

.nm-vtp__dropdown-flyout .audi-dropdown-flyout__current-label {
	color: var(--color-white);
}

.nm-vtp__dropdown-flyout .audi-dropdown-flyout__label {
	border-bottom: 1px solid var(--color-grey-30);
}

.nm-vtp__dropdown-flyout .audi-dropdown-flyout__label:hover,
.nm-vtp__dropdown-flyout[data-state="open"] .audi-dropdown-flyout__label {
	border-bottom: 2px solid var(--color-white);
}

.nm-vtp__dropdown-flyout[data-state="open"] .audi-dropdown-flyout__label {
	background: var(--color-grey-70);
}

.nm-vtp__dropdown-flyout .audi-down-small,
.nm-vtp__dropdown-flyout .nm-icon-cancel {
	color: var(--color-white);
	fill: var(--color-white);
	stroke: var(--color-white);
}

.nm-vtp__dropdown-flyout .audi-dropdown-flyout__group-label {
	color: var(--color-grey-50);
}

@media all and (min-width: 480px) {
	.nm-vtp__link-container.nm-vtp--active {
		display: flex;
	}

	.nm-vtp:not(.nm-vtp--modelpage) .nm-vtp__link-container.nm-vtp--active {
		align-items: flex-end;
	}

	.nm-vtp__link-container > a {
		display: inline-block;
		width: auto;
	}

	.nm-vtp__link-container .nm-vtp__link-new-cars {
		margin-right: var(--space-xl);
	}
}

@media all and (min-width: 768px) {
	.nm-vtp__dropdown-flyout {
		margin-bottom: var(--space-s);
		width: auto;
	}

	.nm-vtp--modelpage .nm-vtp__label-available-cars {
		margin: calc(var(--space-l) - var(--space-s)) 0 var(--space-l);
		width: auto;
	}

	.nm-vtp__link-container {
		margin: var(--space-m) 0 var(--space-xxs) var(--space-xl);
		width: auto;
	}

	.nm-vtp__label-available-cars + .nm-vtp__link-container {
		margin-top: var(--space-m);
	}

	.nm-vtp__link-container > a {
		margin-bottom: 0;
	}

	.nm-vtp__eec-container {
		margin-top: var(--space-s);
		padding: var(--space-xs) var(--space-column);
	}

	.nm-vtp__eec-container > div {
		display: inline-flex;
	}

	.nm-vtp__eec-co2 {
		margin: 0px var(--space-m);
	}

	.nm-vtp__eec-fuel {
		margin-bottom: 0;
	}

	.nm-vtp__eec-link {
		margin-top: 0;
	}
}

@media all and (min-width: 1024px) {
	.nm-vtp__label-available-cars,
	.nm-vtp__link-container {
		margin: 0;
		width: auto;
	}

	.nm-vtp__dropdown-flyout,
	.nm-vtp__dropdown-flyout[data-state="open"] {
		margin-left: var(--space-xl);
	}

	.nm-vtp__label-available-cars {
		margin: calc(var(--space-l) - var(--space-s)) 0 var(--space-l);
		width: auto;
	}

	.nm-vtp__link-container,
	.nm-vtp__label-available-cars + .nm-vtp__link-container {
		margin: var(--space-m) 0 var(--space-xxs) var(--space-xl);
		width: auto;
	}

	.nm-vtp:not(.nm-vtp--modelpage) .nm-vtp__link-container.nm-vtp--active {
		align-items: flex-start;
	}

	.nm-vtp__eec-container.nm-vtp--active {
		display: flex;
	}

	.nm-vtp__link-new-cars {
		margin-right: var(--space-xl);
	}

	.nm-vtp__eec-container {
		margin-top: var(--space-s);
	}
}
