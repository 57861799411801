.nm-module-mediatext-combination-fullwidth-framed {
	padding: var(--space-xxxl) 4%;
	width: 92%;
}

.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-media-item {
	overflow: hidden;
	position: relative;
	width: 100%;
}

.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-media-item video {
	position: absolute;
	top: 0px;
	width: 100%;
}

.nm-module-mediatext-combination-fullwidth-framed .nm-basis-video-player,
.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-image {
	padding-bottom: 100%;
}

.nm-module-mediatext-combination-fullwidth-framed .nm-basis-video-play-button {
	cursor: pointer;
	height: 74px;
	left: 0;
	margin: 0 auto;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 74px;
	z-index: 5;
}

.nm-module-mediatext-combination-fullwidth-framed .nm-basis-video-play-button .nm-icon-play-xxl {
	color: #fff;
	display: inline-block;
	fill: currentColor;
	height: 7.4rem;
	stroke: currentColor;
	width: 7.4rem;
}

.nm-module-mediatext-combination-fullwidth-framed .nm-basis-video-play-button:hover .nm-icon-play-xxl {
	color: #e5e5e5;
}

.nm-module-mediatext-combination-fullwidth-framed .nm-basis-video-player.nm-basis-video-playing .nm-basis-video-play-button {
	display: none;
}

.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-media-item img {
	height: auto;
	position: absolute;
	top: 0;
	width: 100%;
}

.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-textblock {
	padding: var(--space-l) var(--space-xxl) var(--space-xxl);
	width: calc(100% - (2 * var(--space-xxl)));
}

.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-textblock.nm-mediatext-combination-fullwidth__textblock--has-asset-disclaimer {
	padding-top: 0;
}

.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-textblock.nm-mediatext-combination-fullwidth-textblock-black.nm-mediatext-combination-fullwidth-background {
	background-color: #fff;
}

.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-textblock.nm-mediatext-combination-fullwidth-textblock-white.nm-mediatext-combination-fullwidth-background {
	background-color: #000;
}

.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-textblock-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-m);
	max-width: 100%;
}

.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-textblock-link,
.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-textblock-list,
.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-textblock-footnote,
.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-textblock-text {
	margin-bottom: var(--space-l);
	max-width: 100%;
}

.nm-module-mediatext-combination-fullwidth-framed .nm-mini-number {
	padding: var(--space-s) var(--space-m) var(--space-m);
	z-index: 5;
}

.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-media-item > .nm-mini-number {
	display: none;
}

.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-textblock .nm-mini-number {
	background-color: #fff !important;
}

.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-textblock .nm-mini-number .audi-copy-s,
.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-textblock .nm-mini-number .audi-headline-order-3 {
	color: #000;
}

.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-textblock.white.nm-mediatext-combination-fullwidth-background .nm-mini-number {
	background-color: #000 !important;
}

.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-textblock.white.nm-mediatext-combination-fullwidth-background .nm-mini-number .audi-copy-s,
.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-textblock.white.nm-mediatext-combination-fullwidth-background .nm-mini-number .audi-headline-order-3 {
	color: #fff;
}

.nm-module-mediatext-combination-fullwidth-framed .nm-mini-number.nm-mini-number-backgroundcolour-white {
	background-color: #fff;
}

.nm-module-mediatext-combination-fullwidth-framed .nm-mini-number.nm-mini-number-textcolour-black > span {
	color: #000;
}

.nm-module-mediatext-combination-fullwidth-framed .nm-mini-number.nm-mini-number-textcolour-white > span {
	color: #fff;
}

.nm-module-mediatext-combination-fullwidth-framed .nm-mini-number .nm-mini-number-digit {
	font-weight: var(--font-weight-bold);
}

.nm-module-mediatext-combination-fullwidth-framed .nm-mini-number .nm-mini-number-unit {
	padding: 0 0 var(--space-xxs) var(--space-xxs);
}

.nm-module-mediatext-combination-fullwidth-framed .nm-mini-number .nm-mini-number-text {
	padding: 0 0 var(--space-xs) var(--space-s);
}

.nm-module-mediatext-combination-fullwidth-framed .nm-mini-number .nm-mini-number-headline {
	display: block;
}

.nm-module-mediatext-combination-fullwidth-framed__asset-disclaimer--desktop {
	display: none;
	padding-top: var(--space-s);
}

.nm-module-mediatext-combination-fullwidth-framed__asset-disclaimer--mobile {
	margin-bottom: var(--space-xxl);
	padding-top: var(--space-s);
}

.nm-module-mediatext-combination-fullwidth-framed__asset-disclaimer--inverted {
	color: var(--color-white);
}

@media all and (min-width: 768px) {
	.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-framed-content-wrapper {
		display: flex;
		overflow: hidden;
		position: relative;
	}

	.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-media-item {
		position: relative;
		width: 50%;
	}

	.nm-module-mediatext-combination-fullwidth-framed.textbox-left .nm-mediatext-combination-fullwidth-media-item {
		order: 2;
	}

	.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-textblock {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-top: var(--space-xxl);
		position: relative;
		width: calc(50% - 2 * var(--space-xxl));
	}

	.nm-module-mediatext-combination-fullwidth-framed.textbox-left .nm-mediatext-combination-fullwidth-textblock {
		order: 1;
	}

	.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-textblock-link {
		margin-bottom: 0;
	}

	.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-media-item > .nm-mini-number {
		bottom: var(--space-xxl);
		display: inherit;
		position: absolute;
		right: var(--space-xxl);
	}

	.nm-module-mediatext-combination-fullwidth-framed .nm-mini-number .nm-mini-number-text {
		display: block;
		padding: 0px;
	}

	.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-media-item::before {
		display: block;
		padding-top: 100%;
	}

	.nm-module-mediatext-combination-fullwidth-framed .nm-mediatext-combination-fullwidth-textblock .nm-mini-number {
		display: none;
	}

	.nm-module-mediatext-combination-fullwidth-framed__asset-disclaimer--mobile {
		display: none;
	}

	.nm-module-mediatext-combination-fullwidth-framed__asset-disclaimer--desktop {
		display: block;
	}

	.nm-module-mediatext-combination-fullwidth-framed.textbox-left .nm-module-mediatext-combination-fullwidth-framed__asset-disclaimer--desktop {
		text-align: right;
	}
}

@media all and (min-width: 1440px) {
	.nm-module-mediatext-combination-fullwidth-framed {
		padding-left: 12%;
		padding-right: 12%;
		width: 76%;
	}
}
