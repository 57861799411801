/* nm-vtp-image-teaser */

.nm-vtp-image-teaser img {
	height: auto;
	width: 100%;
}

.nm-vtp-image-teaser {
	display: block;
}

.nm-vtp-image-teaser__body {
	align-items: flex-start;
	background-position: center;
	background-size: cover;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin: auto;
	max-width: 1920px;
	min-height: calc((9/16) * 100vw); /* aspect ratio 16 : 9 as soon as possible */
	padding: var(--space-xxl) 4%;
	transition: min-height .3s; /* transition between aspect ratios */
}

/*** HEADLINE / SUBLINE ***/

.nm-vtp-image-teaser__headline {
	margin-bottom: var(--space-xl);
}

.nm-vtp-image-teaser__headline-text {
	font-weight: var(--font-weight-bold);
}

.nm-vtp-image-teaser__headline-text--main {
	display: block;
}

/*** CONFIGURATION FORM ***/

/* common styles for radio buttons and checkboxes */
.nm-vtp-image-teaser__filter-option,
.nm-vtp-image-teaser__additional-filter {
	display: block;
	margin-bottom: var(--space-m);
	position: relative;
}

.nm-vtp-image-teaser__filter-option:last-child,
.nm-vtp-image-teaser__additional-filter:last-child {
	margin-bottom: 0;
}

.nm-vtp-image-teaser__filter-option-input,
.nm-vtp-image-teaser__additional-filter-input {
	/* visually hidden */
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute;
	width: 1px;
}

.nm-vtp-image-teaser__filter-option-label,
.nm-vtp-image-teaser__additional-filter-label {
	cursor: pointer;
	display: inline-block;
	padding-left: calc(24px + var(--space-s)); /* radio button width + space */
	position: relative;
}

.nm-vtp-image-teaser__additional-filter-input:disabled ~ .nm-vtp-image-teaser__additional-filter-label {
	color: var(--color-grey-30);
	cursor: default;
}

.nm-vtp-image-teaser__additional-filter-label.nm-vtp-image-teaser__additional-filter-label,
.nm-vtp-image-teaser__filter-option-label.nm-vtp-image-teaser__filter-option-label /* overwrite color of copy-m color from core-ci */ {
	color: var(--color-grey-30);
}

.nm-vtp-image-teaser__filter-option-input:checked ~ .nm-vtp-image-teaser__filter-option-label,
.nm-vtp-image-teaser__filter-option-label.nm-vtp-image-teaser__filter-option-label:hover,
.nm-vtp-image-teaser__additional-filter-input:checked ~ .nm-vtp-image-teaser__additional-filter-label,
.nm-vtp-image-teaser__additional-filter-input:not(:disabled) ~ .nm-vtp-image-teaser__additional-filter-label:hover {
	color: var(--color-white);
}

/* filter-options / radio buttons */

.nm-vtp-image-teaser__filter-options-group--hidden.nm-vtp-image-teaser__filter-options-group--hidden { /* higher specificity than media query */
	display: none;
}

.nm-vtp-image-teaser__filter-option-input:checked ~ .nm-vtp-image-teaser__filter-option-label,
.nm-vtp-image-teaser__filter-option-label.nm-vtp-image-teaser__filter-option-label:hover {
	color: var(--color-white);
}

.nm-vtp-image-teaser__filter-option-label::before,
.nm-vtp-image-teaser__filter-option-label::after {
	border-radius: 1em;
	content: "";
	display: inline-block;
	height: 1em;
	position: absolute;
	width: 1em;
}

.nm-vtp-image-teaser__filter-option-label::before /* outer radio circle */ {
	border: 1px solid currentColor;
	font-size: 24px; /* determinate size of circle */
	left: 0;
	top: 0;
}

.nm-vtp-image-teaser__filter-option-label::after /* inner radio circle */ {
	background-color: currentColor;
	font-size: 8px; /* determinate size of circle */
	left: calc((24px - 1em) / 2 + 1px); /* (outer width - inner width) / 2 + border-width */
	top: calc((24px - 1em) / 2 + 1px);

}

.nm-vtp-image-teaser__filter-option-label:hover::after,
.nm-vtp-image-teaser__filter-option-input:checked ~ .nm-vtp-image-teaser__filter-option-label::after {
	font-size: 12px; /* determinate size of circle */
}

/* additional filter / checkboxes */

.nm-vtp-image-teaser__additional-filter-group {
	margin-top: var(--space-m);
}

.nm-vtp-image-teaser__filter-options-group--hidden + .nm-vtp-image-teaser__additional-filter-group {
	margin-top: 0;
}

.nm-vtp-image-teaser__additional-filter-label::before {
	border: 1px solid currentColor;
	content: "";
	display: inline-block;
	font-size: 24px; /* determinate size of square */
	height: 1em;
	left: 0;
	position: absolute;
	top: 0;
	width: 1em;
}

.nm-vtp-image-teaser__additional-filter-icon {
	color: var(--color-white);
	display: none;
	font-size: 24px;
	height: 1em;
	left: 1px;
	position: absolute;
	top: 1px;
	width: 1em;
}

.nm-vtp-image-teaser__additional-filter-input:checked ~ .nm-vtp-image-teaser__additional-filter-icon {
	display: block;
}

/*** RESULT LINKS ***/

.nm-vtp-image-teaser__link {
	display: none;
	margin: var(--space-xxl) 0 0;
}

.nm-vtp-image-teaser__link--active {
	display: inline-flex;
}

@media all and (min-width: 786px) {
	.nm-vtp-image-teaser__body {
		padding: var(--space-xxxl) 4%;
	}

	.nm-vtp-image-teaser__options-wrapper {
		display: flex;
	}

	/*** CONFIGURATION FORM ***/

	.nm-vtp-image-teaser__additional-filter-group,
	.nm-vtp-image-teaser__filter-options-group {
		display: inline-block;
	}

	.nm-vtp-image-teaser__filter-option,
	.nm-vtp-image-teaser__additional-filter,
	.nm-vtp-image-teaser__filter-option:last-child,
	.nm-vtp-image-teaser__additional-filter:last-child {
		display: inline-block;
		margin-bottom: var(--space-xxl);
		margin-right: var(--space-xxl);
	}

	.nm-vtp-image-teaser__additional-filter-group {
		margin-top: 0;
	}
	/*** RESULT LINKS ***/

	.nm-vtp-image-teaser__link {
		margin: 0;
	}
}

@media all and (min-width: 1270px) { /* breakpoint: when image with aspect ratio 16:9 becomes higher than 715px --> change aspect ratio */
	.nm-vtp-image-teaser__body {
		min-height: calc((5/16) * 100vw); /* aspect ratio of image 16:5 as soon as possible */
	}
}
