.nm-basic-mininumber-xl {
	padding: var(--space-s) var(--space-m) var(--space-m);
	width: auto;
}

.nm-basic-mininumber-xl--background-white {
	background-color: var(--color-white);
}

.nm-basic-mininumber-xl--text-white {
	color: var(--color-white);
}

.nm-basic-mininumber-xl__content {
	align-items: flex-end;
	display: flex;
	flex-wrap: wrap;
}

.nm-basic-mininumber-xl__headline {
	font-weight: var(--font-weight-bold);
}

.nm-basic-mininumber-xl__digits-unit-wrapper {
	align-items: baseline;
	display: flex;
	flex-wrap: nowrap;
	margin-right: var(--space-s);
}

.nm-basic-mininumber-xl__digits {
	font-weight: var(--font-weight-bold);
}

.nm-basic-mininumber-xl__digits + .nm-basic-mininumber-xl__unit {
	margin-bottom: var(--space-xxs);
	margin-left: var(--space-xxs);
}

.nm-basic-mininumber-xl__digits-unit-wrapper + .nm-basic-mininumber-xl__text {
	margin-bottom: var(--space-xs);
}

.nm-basic-mininumber-xl__digits-unit-wrapper--no-digits + .nm-basic-mininumber-xl__text {
	margin-bottom: 0;
}

@media all and (min-width: 768px) {
	.nm-basic-mininumber-xl {
		display: inline-block;
	}

	.nm-basic-mininumber-xl__content {
		align-items: flex-start;
		flex-direction: column;
	}

	.nm-basic-mininumber-xl__digits-unit-wrapper {
		margin-right: 0;
	}

	.nm-basic-mininumber-xl__digits-unit-wrapper + .nm-basic-mininumber-xl__text {
		margin-bottom: 0;
	}
}
