.nm-module-footnotes-block {
	padding: var(--space-xxl) 4%;
	width: 92%;
}

.nm-module-footnotes-block[data-theme="dark"] {
	background-color: #4c4c4c;
}

.nm-module-footnotes-block .nm-footnotes-block-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-s);
}

.nm-module-footnotes-block .nm-footnotes-block-table {
	display: table;
}

.nm-module-footnotes-block .nm-footnotes-block-row {
	display: table-row;
}

.nm-module-footnotes-block .nm-footnotes-block-column-footnote {
	display: table-cell;
	padding-bottom: var(--space-m);
	padding-right: var(--space-xs);
	text-align: right;
}

.nm-module-footnotes-block .nm-footnotes-block-column-text {
	display: table-cell;
	padding-bottom: var(--space-m);
}

/* footnote in drawer */

@media all and (min-width: 768px) {
	.nm-module-drawer .nm-drawer-content .nm-module-footnotes-block {
		padding-left: 12%;
		padding-right: 12%;
		width: 76%;
	}
}
