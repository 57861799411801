.nm-module-mediatext-combination-fullwidth-wide > .audi-limit-to-container {
	display: flex;
	flex-direction: column;
	position: relative;
}

.nm-module-mediatext-combination-fullwidth-wide.nm-columnreverse > .audi-limit-to-container {
	flex-direction: column-reverse;
	padding-bottom: var(--space-xxxl);
}

.nm-module-mediatext-combination-fullwidth-wide.nm-mediatext-combination-with-frame {
	padding: var(--space-xxxl) var(--space-side-margin) var(--space-xxl) var(--space-side-margin);
}

/* textblock */
.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock-wrapper {
	display: flex;
}

.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock {
	display: inline-block;
	margin: var(--space-xxxl) var(--space-side-margin) 0 var(--space-side-margin);
}

.nm-module-mediatext-combination-fullwidth-wide.nm-mediatext-combination-with-frame:not(.nm-columnreverse) .nm-mediatext-combination-textblock {
	margin: 0;
}

.nm-module-mediatext-combination-fullwidth-wide.nm-columnreverse.nm-mediatext-combination-fullwidth-wide--has-mininumber .nm-mediatext-combination-textblock {
	margin: var(--space-xxl) var(--space-side-margin) 96px var(--space-side-margin);
}

.nm-module-mediatext-combination-fullwidth-wide.nm-columnreverse.nm-mediatext-combination-with-frame.nm-mediatext-combination-fullwidth-wide--has-mininumber .nm-mediatext-combination-textblock {
	display: inline-block;
	margin: var(--space-xxl) 0 100px 0;
}

.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock.nm-white {
	background-color: #fff;
	color: #000;
}

.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock.nm-transparent {
	background-color: transparent;
}

.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock.nm-padding {
	padding: var(--space-l) var(--space-xxl) var(--space-xxl) var(--space-xxl);
	width: 92%;
}

.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock .nm-mediatext-combination-textblock-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-m);
}

.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock .nm-mediatext-combination-textblock-list,
.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock .nm-mediatext-combination-textblock-smalltext-fussnotentext {
	margin-top: var(--space-m);
}

.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock .nm-mediatext-combination-textblock-button {
	margin: var(--space-l) 0;
	position: relative;
}

/* mini number */
.nm-mediatext-combination-fullwidth-wide__mininumber {
	margin: var(--space-l) var(--space-column) var(--space-xxl);
}

.nm-mediatext-combination-with-frame .nm-mediatext-combination-fullwidth-wide__mininumber {
	margin: var(--space-l) 0 var(--space-xxl);
}

.nm-module-mediatext-combination-fullwidth-wide.nm-columnreverse .nm-mediatext-combination-fullwidth-wide__mininumber {
	bottom: 0;
	position: absolute;
	width: calc(100% - 2 * var(--space-column) - 2 * var(--space-m));
	z-index: 1;
}

.nm-module-mediatext-combination-fullwidth-wide.nm-columnreverse.nm-mediatext-combination-with-frame {
	padding-bottom: 0;
}

.nm-module-mediatext-combination-fullwidth-wide.nm-columnreverse.nm-mediatext-combination-with-frame .nm-mediatext-combination-fullwidth-wide__mininumber {
	width: calc(100% - 2 * var(--space-m));
}

/* media-container */
.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-media-container.nm-mediatext-combination-media-frame {
	margin-left: 4%;
	margin-right: 4%;
}

.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-media-video-play-button {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAtCAYAAAA6GuKaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpBQzI0N0JCQ0M5RTgxMUU0QUNFQkQyOEE3RTBEQTE3MSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpBQzI0N0JCREM5RTgxMUU0QUNFQkQyOEE3RTBEQTE3MSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkFDMjQ3QkJBQzlFODExRTRBQ0VCRDI4QTdFMERBMTcxIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkFDMjQ3QkJCQzlFODExRTRBQ0VCRDI4QTdFMERBMTcxIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+bd2AwQAAAKlJREFUeNrs2cENgCAQRFEwVqH9l2YdSAHGC/MHSGaPnF7IMhugttauslkdZcMKOuifOr8Wa63PKsAeFHfaI+igg14Q3eNpz512wJH2oOFYT5Nw9CBScDw9CLgl8tRwW04r4dbhooLbJ6ICPmWMj8KnoPvNaC/0KNiOVoCtaBXYhlaCLWg1GEcTYBRNgTE0CUbQNFiOdoDzhBB00EEPpFT+EYMOen69AgwAC94riY2B00QAAAAASUVORK5CYII=");
	cursor: pointer;
	height: 45px;
	left: 0;
	margin: 0 auto;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 45px;
	z-index: 5;
}

.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-media-video-play-button:hover {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAtCAYAAAA6GuKaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpBQzI0N0JDMEM5RTgxMUU0QUNFQkQyOEE3RTBEQTE3MSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpENkM3MEI1NEM5RTgxMUU0QUNFQkQyOEE3RTBEQTE3MSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkFDMjQ3QkJFQzlFODExRTRBQ0VCRDI4QTdFMERBMTcxIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkFDMjQ3QkJGQzlFODExRTRBQ0VCRDI4QTdFMERBMTcxIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+UfhEUwAAALFJREFUeNrs2bENgCAUBFAxLqQz2No5pp2tM7gSMoCxkLuDn9yVVC/k83+AlHOeh2AZh4Ax2uiPTG+L67bfvQCv81hcHkYbbXSH6NKeYu60Ak4pDzacVtNMOPUgsuD07sGAS1oeGi7r00i4dLig4PKJiIA3GeO18CbocjOKha4Fy9EIsBSNAsvQSLAEjQbT0QwwFc0C09BMMAXNBsPRCrCfEIw22uj/Sf5HNNro9nkEGAAcjiu3j8hcTAAAAABJRU5ErkJggg==");
}

.nm-module-mediatext-combination-fullwidth-wide .nm-media-item-video-autoplay .nm-mediatext-combination-media-video-play-button {
	z-index: -1;
}

.nm-module-mediatext-combination-fullwidth-wide .nm-video-player.nm-basis-video-playing .nm-mediatext-combination-media-video-play-button {
	display: none;
}

.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-media-container {
	background-color: transparent;
	height: 0;
	padding-bottom: 56.2%;
	position: relative;
	z-index: 0;
}

.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-image,
.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-video {
	height: auto;
	position: relative;
	width: 100%;
}

.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-video-stillframe img {
	height: auto;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

.nm-module-mediatext-combination-fullwidth-wide .nm-media-item-video-autoplay.nm-basis-video-playing .nm-mediatext-combination-video-stillframe img {
	z-index: -1;
}

.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-media-item.nm-basis-video-playing .nm-mediatext-combination-video-stillframe {
	display: none;
}

@media all and (min-width: 768px) {
	/* mini number */
	.nm-mediatext-combination-fullwidth-wide__mininumber,
	.nm-mediatext-combination-with-frame .nm-mediatext-combination-fullwidth-wide__mininumber,
	.nm-module-mediatext-combination-fullwidth-wide.nm-columnreverse .nm-mediatext-combination-fullwidth-wide__mininumber,
	.nm-module-mediatext-combination-fullwidth-wide.nm-columnreverse.nm-mediatext-combination-with-frame .nm-mediatext-combination-fullwidth-wide__mininumber {
		bottom: var(--space-xxl);
		margin: 0;
		position: absolute;
		right: var(--space-column);
		width: auto;
		z-index: 1;
	}

	/* textblock */
	.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock-list-wrapper .nm-mediatext-combination-textblock-list {
		width: 32vw;
	}

	.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock {
		display: inline-block;
		width: 76%;
	}

	.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock-wrapper.nm-topcenter .nm-mediatext-combination-textblock,
	.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock-wrapper.nm-bottomcenter .nm-mediatext-combination-textblock {
		text-align: center;
	}

	.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock-wrapper.nm-topright .nm-mediatext-combination-textblock,
	.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock-wrapper.nm-bottomright .nm-mediatext-combination-textblock {
		text-align: right;
	}

	.nm-module-mediatext-combination-fullwidth-wide.nm-columnreverse.nm-mediatext-combination-fullwidth-wide--has-mininumber .nm-mediatext-combination-textblock,
	.nm-module-mediatext-combination-fullwidth-wide.nm-columnreverse.nm-mediatext-combination-with-frame.nm-mediatext-combination-fullwidth-wide--has-mininumber .nm-mediatext-combination-textblock {
		margin-bottom: 0;
	}

	.nm-module-mediatext-combination-fullwidth-wide.nm-mediatext-combination-with-frame .nm-mediatext-combination-textblock-wrapper.top .nm-mediatext-combination-textblock {
		margin: 0;
	}

	.nm-module-mediatext-combination-fullwidth-wide.nm-mediatext-combination-with-frame.nm-columnreverse {
		padding-bottom: var(--space-xxxl);
	}

	.nm-module-mediatext-combination-fullwidth-wide.nm-columnreverse .nm-mediatext-combination-fullwidth-wide__mininumber-mediacontent-wrapper {
		position: relative;
	}

	.nm-module-mediatext-combination-fullwidth-wide:not(.nm-columnreverse) .nm-mediatext-combination-textblock-wrapper {
		margin-bottom: var(--space-xxl);
	}

	.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock-wrapper.nm-topcenter,
	.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock-wrapper.nm-bottomcenter,
	.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock-wrapper.nm-topcenter .nm-mediatext-combination-textblock-list-wrapper,
	.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock-wrapper.nm-bottomcenter .nm-mediatext-combination-textblock-list-wrapper {
		display: flex;
		justify-content: center;
	}

	.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock-wrapper.nm-topright,
	.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock-wrapper.nm-bottomright {
		justify-content: flex-end;
	}

	.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock-wrapper.nm-topright .nm-mediatext-combination-textblock-list-wrapper,
	.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock-wrapper.nm-bottomright .nm-mediatext-combination-textblock-list-wrapper {
		display: flex;
		justify-content: flex-end;
	}

	.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock-wrapper.nm-topcenter .nm-mediatext-combination-textblock-list,
	.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock-wrapper.nm-bottomcenter .nm-mediatext-combination-textblock-list,
	.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock-wrapper.nm-topright .nm-mediatext-combination-textblock-list,
	.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock-wrapper.nm-bottomright .nm-mediatext-combination-textblock-list {
		text-align: left;
	}
}

@media all and (min-width: 1024px) {
	.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock-list-wrapper .nm-mediatext-combination-textblock-list {
		max-width: 537.6px;
		width: 28vw;
	}

	.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock,
	.nm-module-mediatext-combination-fullwidth-wide.nm-columnreverse .nm-mediatext-combination-textblock {
		width: 60%;
	}
}

@media all and (min-width: 1440px) {
	.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock,
	.nm-module-mediatext-combination-fullwidth-wide.nm-columnreverse .nm-mediatext-combination-textblock {
		width: 52%;
	}
}

@media all and (min-width: 1920px) {
	.nm-module-mediatext-combination-fullwidth-wide .nm-mediatext-combination-textblock,
	.nm-module-mediatext-combination-fullwidth-wide.nm-columnreverse .nm-mediatext-combination-textblock {
		width: 60%;
	}
}
