.nm-module-definition-list {
	padding: var(--space-xxxl) 4%;
	width: 92%;
}

.nm-module-definition-list[data-theme="dark"] {
	background-color: #4c4c4c;
}

.nm-module-definition-list .nm-definition-list-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-xxl);
}

.nm-module-definition-list .nm-definition-list-row {
	display: flex;
}

.nm-module-definition-list .nm-definition-list-row > div {
	border-top: 1px solid #000;
	padding: var(--space-s) 0 var(--space-l);
}

.nm-module-definition-list .nm-definition-list-row > .nm-definition-list-firstvalue {
	border-top: 3px solid #000;
}

.nm-module-definition-list .nm-definition-list-row .nm-definition-list-column-label {
	font-weight: var(--font-weight-bold);
	padding-right: 4vw;
	width: calc((48% * 100 / 92) - var(--space-xs));
	word-wrap: break-word;
}

.nm-module-definition-list .nm-definition-list-row .nm-definition-list-column-value {
	padding-top: calc(var(--space-s) + 2px);
	width: calc(44% * 100 / 92);
	word-wrap: break-word;
}

.nm-module-definition-list[data-theme="dark"] .nm-definition-list-row > div {
	border-color: #fff !important;
}

@media all and (min-width: 768px) {
	.nm-module-definition-list {
		padding-left: 12%;
		padding-right: 12%;
		width: 76%;
	}

	.nm-module-definition-list .nm-definition-list-row .nm-definition-list-column-value.nm-definition-list-firstvalue {
		border-top: 1px solid #000;
	}

	.nm-module-definition-list .nm-definition-list-row .nm-definition-list-column-label {
		border-top: 3px solid #000;
		margin-right: var(--space-xs);
		padding-right: 0px;
		width: calc((32% * 100 / 76) - var(--space-xs));
	}

	.nm-module-definition-list .nm-definition-list-row .nm-definition-list-column-value {
		width: calc(44% * 100 / 76);
	}

	.nm-module-definition-list  .nm-definition-list-column-value.nm-definition-list-lastvalue {
		border-bottom: 1px solid #000;
	}
}

@media all and (min-width: 1024px) {
	.nm-module-definition-list .nm-definition-list-row .nm-definition-list-column-label {
		width: calc((24% * 100 / 76) - var(--space-xs));
	}

	.nm-module-definition-list  .nm-definition-list-row .nm-definition-list-column-value {
		width: calc(52% * 100 / 76);
	}
}
