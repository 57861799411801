/* main */
.audi-stage-large {
	--audi-header-height: 50px; /* old navigation mobile height */
	margin-bottom: 0;
	max-width: 1920px;
	padding: 0;
	position: relative;
	user-select: none;
	width: 100%;
}

@media all and (min-width: 1111px) { /* old navigation breakpoint for mobile to desktop switch */
	.audi-stage-large {
		--audi-header-height: 70px; /* old navigation desktop height */
	}
}

.audi-stage-large.audi-stage-large--below-one-header { /* one header variation */
	--audi-header-height: 73px;
}

.audi-stage-large--inverted {
	color: var(--color-white);
}
/* footnotes patch */
.audi-stage-large .audi-j-footnote-reference {
	color: inherit;
}
/* end main */

/* above the fold */
/* visible frame height calculation and handling */
.audi-stage-large__above-the-fold-wrapper {
	height: calc((var(--custom-vh, 1vh) * 100) - var(--audi-header-height, 73px));
	max-height: 1024px;
	min-height: 480px;
	overflow: hidden;
	position: relative;
	width: 100%;
}
/* end - above the fold */

/* media item */
.audi-stage-large__media-item {
	height: 100%;
	position: absolute;
	width: 100%;
}

.audi-stage-large__media-item::after {
	background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 0) 100%);
	bottom: 0;
	content: " ";
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	width: 100%;
}

.audi-stage-large--inverted .audi-stage-large__media-item::after {
	background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
}

.audi-stage-large--top-aligned .audi-stage-large__media-item::after {
	background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 1) 100%);
}

.audi-stage-large--inverted.audi-stage-large--top-aligned .audi-stage-large__media-item::after {
	background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 1) 100%);
}

.audi-stage-large--no-bottom-shader.audi-stage-large--top-aligned .audi-stage-large__media-item::after {
	background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%, rgba(255, 255, 255, 1) 100%);
}

.audi-stage-large--inverted.audi-stage-large--no-bottom-shader.audi-stage-large--top-aligned .audi-stage-large__media-item::after {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%);
}

.audi-stage-large__image {
	height: 100%;
	object-fit: cover;
	position: relative;
	width: 100%;
}
/* end media item */

/* info cluster */
.audi-stage-large__info-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-end;
	width: 100%;
}

.audi-stage-large--top-aligned .audi-stage-large__info-wrapper {
	justify-content: space-between;
}

@media all and (min-width: 768px) {
	.audi-stage-large--top-aligned .audi-stage-large__info-wrapper {
		justify-content: flex-start;
	}
}

.audi-stage-large--right-aligned .audi-stage-large__info-wrapper {
	align-items: flex-end;
	text-align: right;
}

.audi-stage-large__text-container {
	padding: var(--space-l) 4%;
	width: 92%;
	z-index: 1;
}

.audi-stage-large__links-container {
	margin-top: - var(--space-xs);
	padding: 0 4% var(--space-xl);
	width: 92%;
}

@media all and (min-width: 480px) {
	.audi-stage-large__links-container {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
	}

	.audi-stage-large--right-aligned .audi-stage-large__links-container {
		justify-content: flex-end;
	}
}

@media all and (min-width: 768px) {
	.audi-stage-large__text-container {
		padding: var(--space-xl) 4% var(--space-l);
	}
}

@media all and (min-width: 1024px) {
	.audi-stage-large__text-container {
		padding: var(--space-xl) 4% var(--space-l);
	}
}

@media all and (min-width: 1566px) { /* at this size the inner content reaches the 1440px width and should stop growing */
	.audi-stage-large__text-container {
		max-width: 1440px;
		padding-bottom: var(--space-l);
		padding-left: calc((100% - 1440px) * .5);
		padding-right: calc((100% - 1440px) * .5);
		padding-top: var(--space-xl);
	}

	.audi-stage-large__links-container {
		max-width: 1440px;
		padding-bottom: var(--space-xl);
		padding-left: calc((100% - 1440px) * .5);
		padding-right: calc((100% - 1440px) * .5);
		padding-top: 0;
	}
}

.audi-stage-large__headline {
	font-weight: var(--font-weight-bold);
}

@media all and (min-width: 1024px) {
	.audi-stage-large__headline {
		max-width: 72%;
	}

	.audi-stage-large--right-aligned .audi-stage-large__headline {
		margin-left: 28%;
	}
}

@media all and (min-width: 1440px) {
	.audi-stage-large__headline {
		font-size: 3.6rem;
		line-height: 5.4rem;
	}
}

@media all and (min-width: 1920px) {
	.audi-stage-large__headline {
		font-size: 4.2rem;
		line-height: 6.6rem;
	}
}

.audi-stage-large__subline {
	font-weight: var(--font-weight-bold);
}

@media all and (min-width: 1024px) {
	.audi-stage-large__subline {
		max-width: 44%;
	}

	.audi-stage-large--right-aligned .audi-stage-large__subline {
		margin-left: 56%;
	}
}

.audi-stage-large__headline + .audi-stage-large__subline {
	margin-top: var(--space-xs);
}

.audi-stage-large__primary-link,
.audi-stage-large__secondary-link {
	margin-top: var(--space-xs);
}

@media all and (min-width: 480px) {
	.audi-stage-large__primary-link {
		margin-right: var(--space-xs);
	}

	.audi-stage-large--right-aligned .audi-stage-large__primary-link {
		margin-right: 0;
	}

	.audi-stage-large--right-aligned .audi-stage-large__secondary-link {
		margin-left: var(--space-xs);
	}
}
/* end info cluster */

/* eec */
.audi-stage-large__eec-container {
	background-color: var(--color-white);
	padding: var(--space-s) 4% var(--space-xxl);
	width: 92%;
}

@media all and (min-width: 1566px) {
	.audi-stage-large__eec-container {
		max-width: 1440px;
		padding-bottom: var(--space-xxl);
		padding-left: calc((100% - 1440px) * .5);
		padding-right: calc((100% - 1440px) * .5);
		padding-top: var(--space-s);
	}
}

.audi-stage-large--inverted .audi-stage-large__eec-container {
	background-color: var(--color-black);
}

.audi-stage-large__asset-disclaimer {
	margin-bottom: var(--space-s);
}

.audi-stage-large__asset-disclaimer:last-child {
	margin-bottom: 0;
}

.audi-stage-large__eec-duplets-wrapper {
	display: flex;
	flex-flow: row wrap;
	margin-bottom: calc(var(--space-m) - var(--space-xs));
}

@media all and (min-width: 1024px) {
	.audi-stage-large__eec-duplets-wrapper {
		margin-bottom: calc(var(--space-s) - var(--space-xs));
	}
}

.audi-stage-large__eec-fuel,
.audi-stage-large__eec-co2,
.audi-stage-large__eec-class {
	margin-bottom: var(--space-xs);
	margin-right: var(--space-l);
}
/* end eec */
