.nm-basic-mininumber-s {
	display: inline-block;
	padding: var(--space-s) var(--space-m) var(--space-m);
	width: auto;
}

.nm-basic-mininumber-s--text-white {
	color: var(--color-white);
}

.nm-basic-mininumber-s__headline {
	font-weight: var(--font-weight-bold);
}

.nm-basic-mininumber-s__digits-unit-wrapper {
	align-items: baseline;
	display: flex;
	flex-wrap: nowrap;
}

.nm-basic-mininumber-s__digits {
	font-weight: var(--font-weight-bold);
}

.nm-basic-mininumber-s__digits + .nm-basic-mininumber-s__unit {
	margin-left: var(--space-xs);
}

.nm-basic-mininumber-s__digits-unit-wrapper + .nm-basic-mininumber-s__text,
.nm-basic-mininumber-s__headline + .nm-basic-mininumber-s__content > .nm-basic-mininumber-s__text {
	margin-top: var(--space-xs);
}
