.nm-module-numbers {
	display: block; /* we need it because it's a custom element */
	padding: var(--space-xxxl) 0;
	text-align: center;
	width: 100%;
}

.nm-module-numbers[data-theme="dark"] {
	background-color: #000;
}

.nm-module-numbers .nm-numbers-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-xxl);
}

.nm-module-numbers .nm-numbers-column {
	border-bottom: 1px solid;
	border-color: rgba(0, 0, 0, .1);
	margin-left: 4%;
	margin-top: calc(var(--space-xxl) / 2);
	opacity: 1;
	padding-bottom: calc(var(--space-xxl) / 2);
	position: relative;
	text-align: center;
	transition: all 750ms cubic-bezier(.445, .05, .55, .95);
	width: 92%;
}

.nm-module-numbers .nm-numbers-columns-wrapper > *:nth-child(2) {
	transition-delay: 350ms;
}

.nm-module-numbers .nm-numbers-columns-wrapper > *:nth-child(3) {
	transition-delay: 700ms;
}

.nm-module-numbers .nm-numbers-column:first-of-type {
	margin-top: 0;
}

.nm-module-numbers .nm-numbers-column:last-of-type {
	border: none !important;
	margin-bottom: 0;
}

.nm-module-numbers[data-theme="dark"] .nm-numbers-column {
	border-color: rgba(255, 255, 255, .2);
}

html.nm-js-enabled .nm-module-numbers.nm-number-hidden .nm-numbers-column {
	opacity: 0;
	transform: translateY(50px);
}

.nm-module-numbers .nm-numbers-column-title {
	display: flex;
	font-weight: bold;
	height: 60px; /* todo right value here */
	justify-content: center;
	margin-bottom: var(--space-m);
	overflow: hidden;
}

.nm-module-numbers .nm-numbers-column-title .nm-numbers-column-title-text {
	align-self: flex-end;
	font-weight: var(--font-weight-bold);
}

.nm-module-numbers .nm-numbers-number {
	margin-bottom: var(--space-m);
}

.nm-module-numbers  .nm-numbers-number-unit {
	display: inline-block;
	margin-bottom: var(--space-xs);
	margin-left: var(--space-xs);
	text-align: left;
}

.nm-module-numbers .nm-numbers-number-figure {
	display: inline-block;
	font-weight: var(--font-weight-bold);
}

@media all and (min-width: 768px) {
	.nm-module-numbers .nm-numbers-columns-wrapper {
		display: flex;
		justify-content: center;
	}

	.nm-module-numbers .nm-numbers-column {
		border-bottom: none;
		border-color: rgba(0, 0, 0, .1);
		border-right: 1px solid;
		display: inline-block;
		margin: 0 2%;
		margin-left: var(--space-xl);
		overflow: hidden;
		padding-right: var(--space-xl);
		top: 0;
		transition: all 750ms cubic-bezier(.445, .05, .55, .95);
		width: calc(28%);
		width: calc(100% - (2 * var(--space-xl)));
	}

	.nm-module-numbers.nm-number-hidden .nm-numbers-column {
		left: 0px;
		transform: translateY(50px);
	}

	.nm-module-numbers .nm-numbers-divider {
		height: auto;
		margin: 0;
		width: 1px;
	}
}
