.nm-feature-gallery-fullwidth {
	padding: 0;
}

.nm-feature-gallery-fullwidth__textblock {
	display: inline-block;
	margin: var(--space-xxxl) 4% var(--space-l);
	width: 92%;
}

.nm-feature-gallery-fullwidth__textblock--textcolor-white > * {
	border-color: var(--color-white);
	color: var(--color-white);
}

.nm-feature-gallery-fullwidth__textblock *:last-child {
	margin-bottom: 0;
}

.nm-feature-gallery-fullwidth__textblock-button {
	margin: 0;
	position: relative;
}

.nm-feature-gallery-fullwidth__textblock-headline {
	font-weight: var(--font-weight-bold);
}

.nm-feature-gallery-fullwidth__textblock-headline,
.nm-feature-gallery-fullwidth__textblock-text,
.nm-feature-gallery-fullwidth__textblock-list {
	margin-bottom: var(--space-m);
}

.nm-feature-gallery-fullwidth__textblock-headline + .nm-feature-gallery-fullwidth__textblock-button,
.nm-feature-gallery-fullwidth__textblock-text + .nm-feature-gallery-fullwidth__textblock-button,
.nm-feature-gallery-fullwidth__textblock-list + .nm-feature-gallery-fullwidth__textblock-button {
	margin-top: calc(var(--space-l) - var(--space-m));
}

.nm-feature-gallery-fullwidth__textblock-smalltext {
	margin-bottom: var(--space-l);
}

.nm-feature-gallery-fullwidth__inputs {
	display: none;
}

.nm-feature-gallery-fullwidth__mininumber-slider-wrapper {
	overflow: hidden;
}

.nm-feature-gallery-fullwidth__mini-number {
	margin: var(--space-l) 4% var(--space-xxl);
	padding: var(--space-s) var(--space-m) var(--space-m);
	z-index: 5;
}

.nm-feature-gallery-fullwidth__textblock ~ .nm-feature-gallery-fullwidth__mininumber-slider-wrapper .nm-feature-gallery-fullwidth__mini-number {
	margin: 0 4% var(--space-xxl);
}

.nm-feature-gallery-fullwidth__mini-number--backgroundcolor-white {
	background-color: var(--color-white);
}

.nm-feature-gallery-fullwidth__mini-number--textcolor-black {
	color: var(--color-black);
}

.nm-feature-gallery-fullwidth__mini-number--textcolor-white {
	color: var(--color-white);
}

.nm-feature-gallery-fullwidth__mini-number-unit {
	padding: 0 0 var(--space-xxs) var(--space-xxs);
}

.nm-feature-gallery-fullwidth__mini-number-text {
	padding: 0 0 var(--space-xs) var(--space-s);
}

.nm-feature-gallery-fullwidth__mini-number-headline {
	display: block;
}

.nm-feature-gallery-fullwidth__mini-number-digits {
	font-weight: var(--font-weight-bold);
}

.nm-feature-gallery-fullwidth__navigation-area {
	background-repeat: repeat;
	overflow: hidden;
	padding: var(--space-xl) 4%;
	position: relative;
	user-select: none;
	width: auto;
}

.nm-feature-gallery-fullwidth__navigation {
	overflow: hidden;
}

.nm-feature-gallery-fullwidth__navigation-bar {
	display: flex;
	margin: 0;
	overflow: hidden;
	padding: 0;
}

.nm-feature-gallery-fullwidth__navigation-tab {
	display: block;
	flex: 0 1 auto;
	list-style-type: none;
	margin-right: var(--space-xl);
	white-space: nowrap;
}

.nm-feature-gallery-fullwidth__navigation-tab:last-child {
	margin-right: 0;
}

.nm-feature-gallery-fullwidth__navigation-tab:only-child {
	cursor: default;
	pointer-events: none;
}

.nm-feature-gallery-fullwidth__tab-link {
	opacity: .5;
}

.nm-feature-gallery-fullwidth__slider-container-list {
	margin-bottom: 0;
	margin-top: 0;
	padding-left: 0;
}

.nm-feature-gallery-fullwidth__slider {
	height: 0;
	opacity: 0;
	transition: opacity .6s linear;
}

.nm-feature-gallery-fullwidth__item {
	position: relative;
}

.nm-feature-gallery-fullwidth__slider-video-play-button {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAtCAYAAAA6GuKaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpBQzI0N0JCQ0M5RTgxMUU0QUNFQkQyOEE3RTBEQTE3MSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpBQzI0N0JCREM5RTgxMUU0QUNFQkQyOEE3RTBEQTE3MSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkFDMjQ3QkJBQzlFODExRTRBQ0VCRDI4QTdFMERBMTcxIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkFDMjQ3QkJCQzlFODExRTRBQ0VCRDI4QTdFMERBMTcxIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+bd2AwQAAAKlJREFUeNrs2cENgCAQRFEwVqH9l2YdSAHGC/MHSGaPnF7IMhugttauslkdZcMKOuifOr8Wa63PKsAeFHfaI+igg14Q3eNpz512wJH2oOFYT5Nw9CBScDw9CLgl8tRwW04r4dbhooLbJ6ICPmWMj8KnoPvNaC/0KNiOVoCtaBXYhlaCLWg1GEcTYBRNgTE0CUbQNFiOdoDzhBB00EEPpFT+EYMOen69AgwAC94riY2B00QAAAAASUVORK5CYII=");
	cursor: pointer;
	height: 45px;
	left: 50%;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	visibility: hidden;
	width: 45px;
	z-index: 2;
}

.nm-feature-gallery-fullwidth__slider-video-play-button:hover {
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAAAtCAYAAAA6GuKaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpBQzI0N0JDMEM5RTgxMUU0QUNFQkQyOEE3RTBEQTE3MSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpENkM3MEI1NEM5RTgxMUU0QUNFQkQyOEE3RTBEQTE3MSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkFDMjQ3QkJFQzlFODExRTRBQ0VCRDI4QTdFMERBMTcxIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkFDMjQ3QkJGQzlFODExRTRBQ0VCRDI4QTdFMERBMTcxIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+UfhEUwAAALFJREFUeNrs2bENgCAUBFAxLqQz2No5pp2tM7gSMoCxkLuDn9yVVC/k83+AlHOeh2AZh4Ax2uiPTG+L67bfvQCv81hcHkYbbXSH6NKeYu60Ak4pDzacVtNMOPUgsuD07sGAS1oeGi7r00i4dLig4PKJiIA3GeO18CbocjOKha4Fy9EIsBSNAsvQSLAEjQbT0QwwFc0C09BMMAXNBsPRCrCfEIw22uj/Sf5HNNro9nkEGAAcjiu3j8hcTAAAAABJRU5ErkJggg==");
}

.nm-feature-gallery-fullwidth__slider-container {
	background-color: transparent;
	height: 0;
	padding-bottom: 56.25%;
	position: relative;
	width: 100%;
}

.nm-feature-gallery-fullwidth__image,
.nm-feature-gallery-fullwidth__video {
	height: auto;
	position: relative;
	width: 100%;
}

.nm-feature-gallery-fullwidth__video { /* required for inactive slides */
	height: 0;
	visibility: hidden;
}

.nm-feature-gallery-fullwidth__video-stillframe {
	height: auto;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

.nm-feature-gallery-fullwidth__video-stillframe img {
	display: block;
	max-width: 100%;
}

.nm-feature-gallery-fullwidth__navigation-tab-label {
	cursor: pointer;
}

.nm-feature-gallery-fullwidth__slide {
	float: left;
	width: 100%;
}

/* NAVIGATION arrows left right */
.nm-feature-gallery-fullwidth__navigation-arrow-back,
.nm-feature-gallery-fullwidth__navigation-arrow-forward {
	cursor: pointer;
	display: none;
	height: 30px; /* changed 24px to 30px so that the underline is hidden too */
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 48px;
	z-index: 1;
}

.nm-feature-gallery-fullwidth__navigation-arrow-forward {
	right: 3.8%;
}

.nm-feature-gallery-fullwidth__navigation-arrow-back {
	left: 3.8%;
}

.nm-feature-gallery-fullwidth__navigation-arrow-back .nm-icon-arrow,
.nm-feature-gallery-fullwidth__navigation-arrow-forward .nm-icon-arrow {
	height: 2.4rem;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 2.4rem;
}

.nm-feature-gallery-fullwidth__navigation-arrow-back .nm-icon-arrow {
	left: 0;
	transform: translateY(-50%) rotate(180deg);
}

.nm-feature-gallery-fullwidth__navigation-arrow-forward .nm-icon-arrow {
	margin-left: 25px;
}

.nm-feature-gallery-fullwidth__navigation-arrow-back .nm-icon-arrow[data-theme="dark"],
.nm-feature-gallery-fullwidth__navigation-arrow-forward .nm-icon-arrow[data-theme="dark"] {
	fill: var(--color-white);
}

@media all and (min-width: 768px) {
	.nm-feature-gallery-fullwidth__textblock {
		width: 76%;
	}

	.nm-feature-gallery-fullwidth__navigation-bar {
		display: block;
		margin-left: 0;
		width: auto;
	}

	.nm-feature-gallery-fullwidth__navigation-tab {
		display: inline-block;
		padding: 0;
	}

	.nm-feature-gallery-fullwidth__mininumber-slider-wrapper {
		position: relative;
	}

	.nm-feature-gallery-fullwidth__mini-number {
		bottom: 0;
		position: absolute;
		right: 0;
		z-index: 5;
	}

	.nm-feature-gallery-fullwidth__mini-number-text {
		display: block;
		padding: 0;
	}

	/* tab navigation */
	.nm-feature-gallery-fullwidth__navigation-area {
		overflow: hidden;
		text-align: center;
	}

	.nm-feature-gallery-fullwidth__navigation-area .audi-el-link-m {
		border-bottom: 2px solid transparent;
		line-height: 3.2rem;
	}

	/* NAVIGATION arrows left right icon */
	.nm-feature-gallery-fullwidth__navigation-arrow-back,
	.nm-feature-gallery-fullwidth__navigation-arrow-forward {
		display: none !important; /* in no circumstances the arrows should be shown in a breakpoint greater 767px */
	}
}

@media all and (min-width: 1024px) {
	.nm-feature-gallery-fullwidth__textblock {
		width: 60%;
	}

	.nm-feature-gallery-fullwidth__slider-video-play-button {
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpENkM3MEI1N0M5RTgxMUU0QUNFQkQyOEE3RTBEQTE3MSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpENkM3MEI1OEM5RTgxMUU0QUNFQkQyOEE3RTBEQTE3MSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkQ2QzcwQjU1QzlFODExRTRBQ0VCRDI4QTdFMERBMTcxIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkQ2QzcwQjU2QzlFODExRTRBQ0VCRDI4QTdFMERBMTcxIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+51XimAAAAMNJREFUeNrs28ERgCAQQ1HXsQrtvzTrQArwShLg58jtDbDkQrXW7mOjnMdmAQx4sVx/i1X1roDrA/nhSAMGDBgwYMCAAQMGPBm4F/n9djgBLT/SbrTlDjvRtqHlQluntANtf5bU6Ih3WImOKR4qdFTTUqDjquVodGSXHomOBFfVPuCR2DjwaGwUWIGNAauwEWAl1g5WY61gB9YGdmEtYCdWDnZjpeAEbGyXBgwYMGDAgAEDBgwYcF6KbzyAAQOeKZ8AAwD/wC6nBmkA6wAAAABJRU5ErkJggg==");
		height: 60px;
		width: 60px;
	}

	.nm-feature-gallery-fullwidth__slider-video-play-button:hover {
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpENkM3MEI1QkM5RTgxMUU0QUNFQkQyOEE3RTBEQTE3MSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpENkM3MEI1Q0M5RTgxMUU0QUNFQkQyOEE3RTBEQTE3MSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkQ2QzcwQjU5QzlFODExRTRBQ0VCRDI4QTdFMERBMTcxIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkQ2QzcwQjVBQzlFODExRTRBQ0VCRDI4QTdFMERBMTcxIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+sVBznAAAAMtJREFUeNrs260VgDAYQ1HgsBDMgMUxJg7LDKxUGADbJNAXWXdPf76Y9qWUqWsoQ9dYAAP+Wca3xWXdrj/gzmOfOdKAAQMGDBgwYMCAAX8M/BT59nY4AS0/0m605Q470bZHy4W2vtIOtH0sqdERc1iJjikeKnRU01Kg46plbXRkl66JjgQv69YOuCY2DlwbGwVWYGPAKmwEWIm1g9VYK9iBtYFdWAvYiZWD3VgpOAEb26UBAwYMGDBgwIABAwacl55vPIABA/5SbgEGAGlILtWctklpAAAAAElFTkSuQmCC");
	}
}

@media all and (min-width: 1440px) {
	.nm-feature-gallery-fullwidth__textblock {
		margin-left: 12%;
		width: 52%;
	}

	.nm-feature-gallery-fullwidth__slider-video-play-button {
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpDQkI3OTZGMDExMjA2ODExODhDNjhGRkY5MUNDMzY5QSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo3NUJBMEUzRTMxMTAxMUU0ODZFRkMzNTAzMDgwN0RDNCIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo3NUJBMEUzRDMxMTAxMUU0ODZFRkMzNTAzMDgwN0RDNCIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkRBNUMxRDU4MkUyMDY4MTE4OEM2OEZGRjkxQ0MzNjlBIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkNCQjc5NkYwMTEyMDY4MTE4OEM2OEZGRjkxQ0MzNjlBIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+wKDAMgAAAQpJREFUeNrs3bFtAzEQAEHTcBVy/6W5Dsq5EicGbk+z8UcDgs9jwnPvfXzo3/tEABq0QIMGLdCgBXpqX3/98Jzzg+u138n624q2dYAWaNACDRq0QIMWaNCgBRq0QIMGLdCglYe+94KGvXDrqGOn9ugydu5nWMVOnjqK2NnjXQ07fY4uYecHlgr2ismwgL1mBJ+OvequYzL2ukulqdgrb+8mYq+EPueAfkfkddBTkVdBT0ZeAz0deQV0ATkPXUFOQ5eQs9A15CR0ETkHXUVOQZeRM9B15AT0BuRVIzhogQYNWqBBCzRo0AINWqBBgxZo0AINGrRAT+54z9CKBi3QoEELNGiBBv3mPQUYAAmLNOPXOjSWAAAAAElFTkSuQmCC");
		height: 90px;
		width: 90px;
	}

	.nm-feature-gallery-fullwidth__slider-video-play-button:hover {
		background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpDQkI3OTZGMDExMjA2ODExODhDNjhGRkY5MUNDMzY5QSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo2NTg4MDVGOTMxMTYxMUU0ODZFRkMzNTAzMDgwN0RDNCIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo2NTg4MDVGODMxMTYxMUU0ODZFRkMzNTAzMDgwN0RDNCIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkRBNUMxRDU4MkUyMDY4MTE4OEM2OEZGRjkxQ0MzNjlBIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkNCQjc5NkYwMTEyMDY4MTE4OEM2OEZGRjkxQ0MzNjlBIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+8fwTfgAAARRJREFUeNrs3bFxAjEQQFHjcRW4HVIyyiQjpR3qOBfgxIln9i/vxxe90ei0SnQ6juP8oX/vEwFo0AINGrRAgxboqX399cPL9fbC9bvn4/5tRds6QAs0aIEGDVqgQQs0aNACDVqgQYMWaNDKQz8fd9CwF24ddezUHl3Gzv0Mq9jJU0cRO3u8q2Gnz9El7PzAUsFeMRkWsNeM4NOxV911TMZed6k0FXvl7d1E7JXQl+sN9Dsir4OeirwKejLyGujpyCugC8h56ApyGrqEnIWuISehi8g56CpyCrqMnIGuIyegNyCvGsFBCzRo0AINWqBBgxZo0AINGrRAgxZo0KAFenIn7xla0aAFGjRogQYt0KDfvB8BBgDyJzTj0zVCiAAAAABJRU5ErkJggg==");
	}
}
