.nm-module-mediatext-combination-small {
	margin: var(--space-xxxl) 4%;
	padding: 0 !important;
}

/* textblock */
.nm-module-mediatext-combination-small .nm-mediatext-combination-textblock-headline {
	display: inline-block;
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-xxl);
}

.nm-mediatext-combination-small-textcolor-white {
	color: #fff;
}

.nm-module-mediatext-combination-small .nm-mediatext-combination-textblock-list,
.nm-module-mediatext-combination-small .nm-mediatext-combination-textblock-smalltext-fussnotentext {
	margin-top: var(--space-m);
}

.nm-module-mediatext-combination-small .nm-mediatext-combination-textblock-button {
	margin-top: var(--space-l);
}

/* media item */
.nm-module-mediatext-combination-small .nm-mediatext-combination-media-item {
	position: relative;
}

.nm-module-mediatext-combination-small .nm-mediatext-combination-image,
.nm-module-mediatext-combination-small .nm-mediatext-combination-video {
	height: auto;
	position: relative;
	width: 100%;
}

.nm-module-mediatext-combination-small .nm-mediatext-combination-media-item .nm-mediatext-combination-image {
	width: 100%;
}

.nm-module-mediatext-combination-small .nm-mediatext-combination-small-inputs {
	display: none;
}

.nm-mediatext-combination-media-item__asset-disclaimer {
	margin-top: var(--space-s);
}

.nm-video-player + .nm-mediatext-combination-media-item__asset-disclaimer {
	margin-top: calc(var(--space-s) - 5px); /* nm-video-player patch */
}

/* mini number small */

.nm-module-mediatext-combination-small .nm-mini-number-small-wrapper {
	display: inline-block;
}

.nm-module-mediatext-combination-small .nm-mini-number-small {
	margin-top: var(--space-xxl);
}

.nm-module-mediatext-combination-small .nm-mini-number-small.nm-mininumber-textcolour-black .nm-mininumber-headline .audi-copy-m {
	color: #000;
}

.nm-module-mediatext-combination-small .nm-mini-number-small.nm-mininumber-textcolour-white .nm-mininumber-headline .audi-copy-m {
	color: #fff;
}

.nm-module-mediatext-combination-small .nm-mini-number-small .nm-mininumber-digit {
	font-weight: var(--font-weight-bold);
}

.nm-module-mediatext-combination-small .nm-mini-number-small .nm-mininumber-unit {
	padding: 0 0 var(--space-xxs) var(--space-xs);
}

.nm-module-mediatext-combination-small .nm-mini-number-small .nm-mininumber-subline {
	display: block;
	margin-top: var(--space-xs);
}

.nm-module-mediatext-combination-small .nm-mini-number-small .nm-mininumber-headline {
	display: block;
}

/* tab navigation */
.nm-module-mediatext-combination-small .nm-mediatext-combination-navigation-area {
	background-repeat: repeat;
	display: flex;
	justify-content: center;
	overflow: hidden;
	padding: var(--space-xl) 4%;
	position: relative;
	user-select: none;
	width: auto;
}

.nm-mediatext-combination-small__slider-container--has-asset-disclaimer + .nm-mediatext-combination-navigation-area {
	padding-top: calc(var(--space-l) + var(--space-s) + var(--space-m));
	/* navigation spacing + disclaimer spacing + disclaimer line height */
	/* due to media positioning and size not allowing margisn to add properly */
}

.nm-module-mediatext-combination-small .nm-mediatext-combination-navigation-bar-wrapper-for-overflow-hidden {
	overflow: hidden;
}

.nm-module-mediatext-combination-small .nm-mediatext-combination-navigation-area .nm-mediatext-combination-navigation-bar {
	display: flex;
	margin: 0;
	padding: 0;
}

.nm-module-mediatext-combination-small .nm-mediatext-combination-navigation-area .nm-mediatext-combination-navigation-tab {
	display: block;
	flex: 0 1 auto;
	line-height: 4.1rem;
	list-style-type: none;
	margin-right: var(--space-xl);
	white-space: nowrap;
}

.nm-module-mediatext-combination-small .nm-mediatext-combination-navigation-area .nm-mediatext-combination-navigation-tab:last-child {
	margin-right: 0px;
}

.nm-module-mediatext-combination-small .nm-mediatext-combination-navigation-area .nm-mediatext-combination-link-content {
	opacity: .5;
}

/* slider */
.nm-module-mediatext-combination-small .nm-mediatext-combination-small-slider-container-list {
	margin-bottom: 0px;
	margin-top: 0px;
	padding-left: 0px;
}

.nm-mediatext-combination-slide-margin-down {
	margin-bottom: var(--space-xxl);
}

.nm-mediatext-combination-small__slider-container--has-asset-disclaimer.nm-mediatext-combination-slide-margin-down {
	margin-bottom: calc(var(--space-xxl) + var(--space-s) + var(--space-m));
}

.nm-module-mediatext-combination-small .nm-mediatext-combination-slider {
	height: 0;
	opacity: 0;
	transition: opacity .6s linear;
}

.nm-module-mediatext-combination-small .nm-mediatext-combination-slider-video-play-button {
	cursor: pointer;
	height: 74px;
	left: 0;
	margin: 0 auto;
	position: relative;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 74px;
}

.nm-module-mediatext-combination-small .nm-mediatext-combination-slider-video-play-button .nm-icon-play-xxl {
	color: #fff;
	display: inline-block;
	fill: currentColor;
	height: 7.4rem;
	stroke: currentColor;
	width: 7.4rem;
}

.nm-module-mediatext-combination-small .nm-mediatext-combination-slider-video-play-button:hover .nm-icon-play-xxl {
	color: #e5e5e5;
}

.nm-module-mediatext-combination-small .nm-mediatext-combination-small-video-player-overlay {
	background-color: rgba(0, 0, 0, .3);
	height: calc(100% - 5px); /* height patch */
	position: absolute;
	top: 0;
	width: 100%;
}

.nm-module-mediatext-combination-small .nm-mediatext-combination-small-slider-container {
	height: 0;
	padding-bottom: 56.25%;
	position: relative;
}

.nm-module-mediatext-combination-small .nm-mediatext-combination-video,
.nm-module-mediatext-combination-small .nm-mediatext-combination-slider-video-play-button {
	height: 0;
	visibility: hidden;
}

.nm-module-mediatext-combination-small .nm-mediatext-combination-video-stillframe img {
	height: auto;
	position: relative;
	top: 0;
	width: 100%;
	z-index: 1;
}

.nm-module-mediatext-combination-small .nm-mediatext-combination-slider-label {
	cursor: pointer;
}

.nm-module-mediatext-combination-small .nm-mediatext-combination-slide {
	float: left;
	position: relative;
	width: 100%;
}

/* NAVIGATION arrows left right */
.nm-module-mediatext-combination-small .navigation-arrow-back,
.nm-module-mediatext-combination-small .navigation-arrow-forward {
	cursor: pointer;
	height: 24px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 48px;
	z-index: 1;
}
/* NAVIGATION arrows right */
.nm-module-mediatext-combination-small .navigation-arrow-forward {
	right: 4%;
}

/* NAVIGATION arrows left */
.nm-module-mediatext-combination-small .navigation-arrow-back {
	left: 4%;
}

.nm-mediatext-combination-small__slider-container--has-asset-disclaimer + .nm-mediatext-combination-navigation-area .navigation-arrow-back,
.nm-mediatext-combination-small__slider-container--has-asset-disclaimer + .nm-mediatext-combination-navigation-area .navigation-arrow-forward {
	top: calc(50% + var(--space-s)) !important;
}

.nm-module-drawer[data-theme="light"] .nm-drawer-body .nm-drawer-content .nm-module-mediatext-combination-small .nm-mediatext-combination-navigation-area .navigation-arrow-forward {
	background-image: linear-gradient(90deg, rgba(179, 179, 179, 0) 0%, rgb(179, 179, 179) 70%) !important;
}

.nm-module-drawer[data-theme="dark"] .nm-drawer-body .nm-drawer-content .nm-module-mediatext-combination-small .nm-mediatext-combination-navigation-area .navigation-arrow-forward {
	background-image: linear-gradient(90deg, rgba(76, 76, 76, 0) 0%, rgb(76, 76, 76) 70%) !important;
}

.nm-module-drawer[data-theme="light"] .nm-drawer-body .nm-drawer-content .nm-module-mediatext-combination-small .nm-mediatext-combination-navigation-area .navigation-arrow-back {
	background-image: linear-gradient(-90deg, rgba(179, 179, 179, 0) 0%, rgb(179, 179, 179) 70%) !important;
}

.nm-module-drawer[data-theme="dark"] .nm-drawer-body .nm-drawer-content .nm-module-mediatext-combination-small .nm-mediatext-combination-navigation-area .navigation-arrow-back {
	background-image: linear-gradient(-90deg, rgba(76, 76, 76, 0) 0%, rgb(76, 76, 76) 70%) !important;
}

.nm-module-mediatext-combination-small .navigation-arrow-back .nm-icon-arrow,
.nm-module-mediatext-combination-small .navigation-arrow-forward .nm-icon-arrow {
	height: 2.4rem;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 2.4rem;
}

.nm-module-mediatext-combination-small .navigation-arrow-back .nm-icon-arrow {
	left: 0px;
	transform: translateY(-50%) rotate(180deg);
}

.nm-module-mediatext-combination-small .navigation-arrow-forward .nm-icon-arrow {
	margin-left: 25px;
}

@media all and (min-width: 768px) {
	.nm-module-mediatext-combination-small {
		margin-left: 12%;
		margin-right: 12%;
	}

	.nm-mediatext-combination-small-slider-navigation-wrapper.nm-mediatext-combination-small-image-position-top {
		margin-left: -8%;
		margin-right: -8%;
	}
}

@media all and (min-width: 1024px) {
	.nm-module-mediatext-combination-small {
		margin-left: 12%;
		margin-right: 12%;
	}

	.nm-module-mediatext-combination-small.image-position-top {
		margin-left: 20%;
		margin-right: 20%;
	}

	.nm-module-mediatext-combination-small .nm-mediatext-combination-small-text-slider-navigation-mininumber-wrapper {
		display: inline-block;
	}

	.nm-module-mediatext-combination-small .nm-mediatext-combination-textblock {
		display: inline;
		margin-top: 0;
	}

	/* mininumber */
	.nm-module-mediatext-combination-small .nm-mediatext-combination-small-mininumber-slider-wrapper {
		margin-left: 4%;
		margin-right: 4%;
		position: relative;
		width: 100%;
	}

	.nm-module-mediatext-combination-small .nm-mini-number-small {
		position: relative;
		z-index: 5;
	}

	/* tab navigation */
	.nm-module-mediatext-combination-small .nm-mediatext-combination-navigation-area {
		overflow: hidden;
		text-align: center;
	}

	.nm-module-mediatext-combination-small .nm-mediatext-combination-navigation-bar {
		display: block !important;
	}

	.nm-module-mediatext-combination-small .nm-mediatext-combination-navigation-tab {
		display: inline-block !important;
	}

	.nm-module-mediatext-combination-small .nm-mediatext-combination-navigation-area .nm-mediatext-combination-navigation-bar {
		margin-left: 0px !important;
		width: auto !important;
	}

	.nm-module-mediatext-combination-small .nm-mediatext-combination-navigation-area .nm-mediatext-combination-navigation-tab {
		margin-right: 30px;
		padding: 0;
		width: inherit !important;
	}

	/* SLIDER arrows left right */
	.nm-module-mediatext-combination-small .slider-arrow-back,
	.nm-module-mediatext-combination-small .slider-arrow-forward {
		cursor: pointer;
		display: inline-block;
		position: absolute;
		top: 50%;
		z-index: 1;
	}

	/* SLIDER arrows right */
	.nm-module-mediatext-combination-small .slider-arrow-forward {
		right: 0;
	}

	/* SLIDER arrows left */
	.nm-module-mediatext-combination-small .slider-arrow-back .nm-icon-arrow {
		transform: rotate(180deg);
	}

	/* SLIDER arrows left right icon */
	.nm-module-mediatext-combination-small .slider-arrow-forward .nm-icon-arrow,
	.nm-module-mediatext-combination-small .slider-arrow-back .nm-icon-arrow {
		fill: #fff;
		height: 48px;
		stroke: rgba(0, 0, 0, .15);
		stroke-width: .5;
		width: 48px;
	}

	/* NAVIGATION arrows left right icon */
	.nm-module-mediatext-combination-small .navigation-arrow-back,
	.nm-module-mediatext-combination-small .navigation-arrow-forward {
		display: none !important;
	}

	.nm-mediatext-combination-small-image-position-left {
		float: left;
		width: 52%;
	}

	.nm-mediatext-combination-small-image-position-right {
		float: right;
		width: 52%;
	}

	.nm-module-mediatext-combination-small .nm-mediatext-combination-small-image-position-left.nm-mediatext-combination-small-slider-navigation-wrapper {
		margin-left: -8%;
		margin-right: 4%;
	}

	.nm-mediatext-combination-small-image-position-right.nm-mediatext-combination-small-slider-navigation-wrapper {
		margin-left: 4%;
		margin-right: -8%;
	}

	.nm-mediatext-combination-small-slider-navigation-wrapper {
		margin-left: 4%;
		margin-right: 4%;
	}

	.nm-module-mediatext-combination-small .nm-mediatext-combination-small-slider-container {
		margin-top: 0;
	}
}

@media all and (min-width: 1440px) {
	.nm-module-mediatext-combination-small {
		margin-left: 20%;
		margin-right: 20%;
	}
}
