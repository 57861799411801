.nm-module-scroll-technology {
	position: relative;
	width: 100%;
}

.nm-module-scroll-technology ~ div > * {
	transform: translateZ(0);
}

.nm-module-scroll-technology .nm-scroll-technology-textblock {
	display: inline-block;
	margin: var(--space-xxxl) 4% var(--space-xxl);
	opacity: 1;
	transition: all 750ms cubic-bezier(.445, .05, .55, .95);
	width: 92%;
}

html.nm-js-enabled .nm-module-scroll-technology .nm-scroll-technology-textblock.nm-scroll-technology-textblock-hidden {
	opacity: 0;
	transform: translateY(calc(50px));
}

.nm-module-scroll-technology .nm-scroll-technology-textblock.nm-scroll-technology-textblock-white.nm-scroll-technology-textblock-background {
	background-color: #000;
}

.nm-module-scroll-technology .nm-scroll-technology-textblock.nm-scroll-technology-textblock-black.nm-scroll-technology-textblock-background {
	background-color: #fff;
}

.nm-module-scroll-technology .nm-scroll-technology-textblock.nm-scroll-technology-textblock-background {
	padding: var(--space-l) var(--space-xxl) var(--space-xxl);
	width: calc(92% - (2 * var(--space-xxl)));
}

.nm-module-scroll-technology .nm-scroll-technology-textblock .nm-scroll-technology-textblock-headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-m);
}

.nm-module-scroll-technology .nm-scroll-technology-textblock .nm-scroll-technology-textblock-text {
	margin-bottom: var(--space-m);
}

.nm-module-scroll-technology .nm-scroll-technology-video-container.sticky {
	position: sticky;
	top: 0;
}

.nm-module-scroll-technology .nm-scroll-technology-video-container video {
	display: block;
}

/* stylelint-disable */
/* safari only */
_::-webkit-full-page-media,
_:future,
:root .nm-module-scroll-technology .nm-scroll-technology-video-container video {
	height: 100%;
}
/* stylelint-enable */

.nm-module-scroll-technology *::-webkit-media-controls,
.nm-module-scroll-technology *::-webkit-media-controls-panel {
	appearance: none;
	display: none !important;
}

.nm-module-scroll-technology .nm-scroll-technology-textoverlay-wrapper {
	position: relative;
}

.nm-module-scroll-technology .nm-scroll-technology-textoverlay {
	opacity: 0;
	padding: var(--space-xxl) 4% var(--space-xxxl);
	width: 92%;
}

.nm-module-scroll-technology .nm-scroll-technology-textoverlay-text {
	font-weight: var(--font-weight-bold);
}

.nm-module-scroll-technology .nm-scroll-technology-textoverlay.nm-scroll-technology-highlighttext-two {
	position: absolute;
	top: 0;
}

.nm-module-scroll-technology .nm-scroll-technology-textoverlay.nm-scroll-technology-textoverlay-white .audi-headline-order-3,
.nm-module-scroll-technology .nm-scroll-technology-textoverlay.nm-scroll-technology-textoverlay-white .audi-copy-m {
	color: #fff;
}

@media all and (min-width: 768px) {
	.nm-module-scroll-technology .nm-scroll-technology-textblock {
		margin-right: 20%;
		width: 76%;
	}

	.nm-module-scroll-technology .nm-scroll-technology-textblock.nm-scroll-technology-textblock-background {
		width: calc(76% - (2 * var(--space-xxl)));
	}

	.nm-module-scroll-technology .nm-scroll-technology-textblock.nm-scroll-technology-textblock-right {
		margin-left: 20%;
		margin-right: 4%;
	}

	.nm-module-scroll-technology .nm-scroll-technology-video-container {
		height: 0px;
		padding-bottom: 56.15%;
		position: relative;
	}

	.nm-module-scroll-technology .nm-scroll-technology-textoverlay-wrapper {
		left: 0px;
		padding-bottom: 56.15%;
		position: absolute;
		top: 0px;
		width: 100%;
	}

	.nm-module-scroll-technology .nm-scroll-technology-textoverlay {
		margin: var(--space-xxl) 4%;
		padding: var(--space-l) var(--space-xxl) var(--space-xxl);
		position: absolute;
		top: 0px;
		width: 28%;
	}

	.nm-module-scroll-technology .nm-scroll-technology-textoverlay.nm-scroll-technology-textoverlay-white.nm-scroll-technology-textoverlay-background {
		background-color: #000;
	}

	.nm-module-scroll-technology .nm-scroll-technology-textoverlay.nm-scroll-technology-textoverlay-background {
		background-color: #fff;
	}

	.nm-module-scroll-technology .nm-scroll-technology-textoverlay.nm-scroll-technology-textoverlay-bottom {
		bottom: 0px;
		top: auto;
	}

	.nm-module-scroll-technology .nm-scroll-technology-textoverlay.nm-scroll-technology-textoverlay-right {
		right: 0px;
	}
}

@media all and (min-width: 1024px) {
	.nm-module-scroll-technology .nm-scroll-technology-textblock {
		margin-right: 36%;
		width: 60%;
	}

	.nm-module-scroll-technology .nm-scroll-technology-textblock.nm-scroll-technology-textblock-background {
		width: calc(60% - (2 * var(--space-xxl)));
	}

	.nm-module-scroll-technology .nm-scroll-technology-textblock.nm-scroll-technology-textblock-right {
		margin-left: 36%;
	}
}

@media all and (min-width: 1440px) {
	.nm-module-scroll-technology .nm-scroll-technology-textblock {
		margin-left: 12%;
		width: 52%;
	}

	.nm-module-scroll-technology .nm-scroll-technology-textblock.nm-scroll-technology-textblock-background {
		width: calc(52% - (2 * var(--space-xxl)));
	}

	.nm-module-scroll-technology .nm-scroll-technology-textblock.nm-scroll-technology-textblock-right {
		margin-left: 36%;
	}
}
