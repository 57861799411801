.nm-intro {
	display: block;
	padding: var(--space-xxl) 0 calc(var(--space-xxxl) - var(--space-xl));
}

.nm-intro__headline,
.nm-intro__copy {
	margin-left: auto;
	margin-right: auto;
}

.nm-intro__headline {
	font-weight: var(--font-weight-bold);
	margin-bottom: var(--space-m);
	width: 92%;
}

.nm-intro__copy {
	margin-bottom: var(--space-xxl);
	width: 92%;
}

.nm-intro__list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 0 2%;
}

html.nm-js-enabled .nm-intro__headline,
html.nm-js-enabled .nm-intro__copy,
html.nm-js-enabled .nm-intro__list {
	opacity: 0;
}

.nm-intro.nm-j-intro-show .nm-intro__headline {
	animation: nm-intro-fadein .5s cubic-bezier(.15, 0, .25, 1) forwards, nm-intro-move .5s cubic-bezier(.15, 0, .25, 1) forwards;
}

.nm-intro.nm-j-intro-show .nm-intro__copy {
	animation: nm-intro-fadein .5s cubic-bezier(.15, 0, .25, 1) forwards, nm-intro-move .75s cubic-bezier(.15, 0, .25, 1) forwards;
}

.nm-intro.nm-j-intro-show .nm-intro__list {
	animation: nm-intro-fadein 1s ease-in-out forwards, nm-intro-move 1s cubic-bezier(.15, 0, .25, 1) forwards;
}

.nm-intro__list-item {
	margin: 0 2%;
	margin-bottom: var(--space-xl);
	width: 44%;
}

.nm-intro__icon {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.nm-intro__list-item svg {
	display: block;
	height: 48px;
	margin-bottom: var(--space-xs);
	width: 48px;
}

.nm-intro__list-item svg.nm-icon-news-large {
	background-image: none; /* hack for corzi */
}

.nm-intro__list-item span {
	text-align: center;
	width: 100%;
}

@media all and (min-width: 480px) {
	.nm-intro__list-item {
		width: 28%;
	}
}

@media all and (min-width: 1024px) {
	.nm-intro__headline,
	.nm-intro__copy {
		text-align: center;
	}

	.nm-intro__copy {
		width: 60%;
	}

	.nm-intro__list-item {
		width: 20%;
	}
}

@media all and (min-width: 1440px) {
	.nm-intro__headline {
		width: 76%;
	}

	.nm-intro__list-item {
		width: 12%;
	}
}

@keyframes nm-intro-move {
	from {
		transform: translate(0px, 100px);
	}

	to {
		transform: translate(0px, 0px);
	}
}

@keyframes nm-intro-fadein {
	0% {
		opacity: 0;
	}

	25% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}
